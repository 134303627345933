import Vue from 'vue'
import reportStorageApi from '@/api/report-storage.api'
import { filterRulesMapped } from '@/tools/filterRules'
import { mapMutations, mapActions, mapGetters } from 'vuex'
import {
  getChildrenFromList,
  getDateTimeRange,
  selectorDateTimeValidation,
  ISODateFromUnixTimestamp
} from '@/tools/functions'
import i18n from '@/i18n'
import activityApi from '@/api/activity.api'
import sectorApi from '@/api/sector.api'
import thingApi from '@/api/thing.api'
import driveUnitApi from '@/api/drive-unit.api'
import { TimeRanges, CustomTimeRangeTypes } from '@colven/common-domain-lib/lib'
import SelectorComponent from '@/components/commons/selector/SelectorComponent.vue'
import BreadcrumbComponent from '@/components/commons/breadcrumb/BreadcrumbComponent.vue'
import MachineDetail from '@/components/machine/summary-report/detail/MachineDetailReport.vue'
import { SnackbarStyle } from '@/constants/constants'
import DonutChartContainer from '@/components/machine/summary-report/donut-chart-container/DonutChartContainer.vue'
import machineSummaryReportService from '@/business/machineSummaryReportService'
import autoReportService from '@/business/autoReportService'
import selectorService from '@/business/selectorService'
import {reportToExportConstants} from '../../../constants/machineSummaryReport.constants'
import js2excel from 'js2excel'; 

export default {
  name: 'MachineSummaryReport',
  components: {
    BreadcrumbComponent,
    SelectorComponent,
    MachineDetail,
    DonutChartContainer
  },
  model: {
    prop: 'model'
  },
  /**
   * MODELO
   *
   * sector: String. La clave del sector al que corresponde el reporte, para prefiltrar actividades y tipos de equipos
   *  --> Ejemplo: "FIELD_ENGINEERING"
   */
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    tableData: [],
    tableHeaders: [],
    tableFilterRules: {},
    tableComponentButtons: [],
    exportButtonDisabled: true,
    // headers
    thingHeaders: [],
    workFrontHeaders: [],
    driverHeaders: [],
    // datos de las tablas
    thingData: [],
    workFrontData: [],
    driverData: [],
    // key de los datos de la tabla
    tableDataKey: 'id',
    // id de los tabs
    tabId: 'tab-report-equipments',
    intervalId: null,
    loadingSpinner: true,
    loadingTable: false,
    disableRefreshTable: true,
    timeStampFrom: null,
    timeStampTo: null,
    selectorModel: selectorService.getSelectorModel(true),
    sectorFilter: {
      id: 'sector',
      name: i18n.t('headers.sector'),
      show: false,
      disabled: false,
      showDialog: false,
      singleSelect: true,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    activityFilter: {
      id: 'activity',
      name: i18n.t('headers.activity'),
      show: false,
      disabled: true,
      showDialog: false,
      singleSelect: true,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    typeFilter: {
      id: 'thingType',
      name: i18n.t('headers.thingType'),
      show: false,
      disabled: true,
      showDialog: false,
      singleSelect: true,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    workFrontFilter: {
      id: 'workFront',
      name: i18n.t('headers.workFront'),
      show: false,
      disabled: true,
      showDialog: false,
      singleSelect: false,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    driveUnitFilter: {
      id: 'driveUnit',
      name: i18n.t('driveUnits.title'),
      show: false,
      showDialog: false,
      disabled: false,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    timeFormat: null,
    showSelector: false,
    disableSelector: false,
    tabsHarvesterSummaryReport: [
      {
        id: 'tab-harvester-summary',
        name: i18n.t('summary')
      }
    ],
    tabSelectedHarvesterSummaryReport: 'tab-harvester-summary',
    breadcrumbButtomsHarvesterSummaryReport: [],
    reportDetailModel: {
      loadingSpinner: false,
      autoReport: false,
      autoReportMinutes: 10,
      activities: [],
      driveUnits: [],
      from: null,
      to: null
    },
    // para mostrar los gráficos de tiempo disponible de plantación
    showPlantationAvailableTimeChart: false,
    // datos de los gráficos de tiempo disponible
    availableTime: {
      thingCharts: [],
      things: [],
      driverCharts: [],
      drivers: [],
      workFrontCharts: [],
      workFronts: []
    },
    // datos de los gráficos de tiempo disponible de plantación
    plantationAvailableTime: {
      thingCharts: [],
      things: [],
      driverCharts: [],
      drivers: [],
      workFrontCharts: [],
      workFronts: []
    },
    // datos de los gráficos de tiempo en cada estado
    timeByState: {
      thingCharts: [],
      things: [],
      driverCharts: [],
      drivers: [],
      workFrontCharts: [],
      workFronts: []
    },
    // modelos del DonutChartContainer
    timeByStateDonutChartContainerModel: {
      initialize: false
    },
    availableTimeDonutChartContainerModel: {
      initialize: false
    },
    plantationAvailableTimeDonutChartContainerModel: {
      initialize: false
    },
    // backup de selectores
    filterSwitch: true,
    sectors: [],
    activities: [],
    types: [],
    things: [],
    driveUnits: [],
    dateAndTimeRange: TimeRanges.LAST_HOUR,
    dateAndTimeRangeCustomType: CustomTimeRangeTypes.DATE,
    sinceDate: null,
    sinceTime: null,
    toDate: null,
    toTime: null,
    // minutos del auto-reporte
    autoReportMinutes: 10,
    // nombre del auto-reporte
    autoReportName: null,
    // id del auto-reporte que se está generando y guardando en la bandeja temporal de reportes
    autoReportId: null,
    currentSector: '',
  }),
  async mounted() {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale;
    // Inicializo los datos del selector
    await this.initializeSelector();
    // carga inicial del reporte
    this.initialReportLoad();

    if (this.model.sector) {
      this.disableExportButton(this.model.sector)
    }
  },
  created() {
    // Agrego el botón del breadcrumb para mostrar/ocultar el selector
    /*this.tableComponentButtons.push({
      id: 'export-pdf-report',
      tooltip: "Exportar", // CAMBIAR
      icon: 'download',
      action: ()=>{},
      selectedData: false,
      disabled: true,
    })*/
    this.breadcrumbButtomsHarvesterSummaryReport.push(
      {
        id: 'setting-btn',
        icon: 'settings',
        show: () => {
          return true
        },
        disable: this.disableSelectorButton.bind(this),
        function: this.selector.bind(this)
      });
  },
  beforeDestroy() {
    // Antes de que se destruya la instancia del componente, limpio la variable del contador del autorreporte
    this.closeReportDefer();
    clearInterval(this.intervalId);
    this.intervalId = null;
    this.setBreadcrumbDetails(null);
    // setear en null el id del auto-reporte-actual
    this.setCurrentReportId(null);
  },
  computed: {
    ...mapGetters('breadcrumb', {
      getDetails: 'getDetails'
    })
  },
  methods: {
    ...mapActions({
      'showSnackbar': 'snackbar/showSnackbar',
      'closeSnackbar': 'snackbar/closeSnackbar',
      'setDetails': 'breadcrumb/setDetails',
      'setCurrentReportId': 'autoReport/setCurrentReportId'
    }),
    ...mapMutations({
      'setBreadcrumbDetails': 'breadcrumb/commitDetails'
    }),
    ...mapMutations('reportDefer', {
      showReportDefer: 'showReportDefer',
      closeReportDefer: 'closeReportDefer',
      commitVisible: 'commitVisible'
    }),
    ...mapMutations('app', {
      setEntitySelectorItems: 'setEntitySelectorItems',
      setEntityConfiguration: 'setEntityConfiguration',
      resetEntitySelector: 'resetEntitySelector',
      setSelectedItem: 'setSelectedItem'
    }),
    /**
     * Para el botón del breadcrumb
     */
    selector() {
      this.showSelector = !this.showSelector;
    },
    /**
     * Cambia de tab (breadcrumb)
     * @param {*} id
     */
    tabSelected(id) {
      const detailRef = this.$refs['harvester-report-detail'];
      if (id === 'tab-harvester-summary') {
        this.commitVisible({visible: false});
        this.reportDetailModel.loadingSpinner = false;
        this.loadingSpinner = true;
        // Se resetea el breadcrumb al volver al Resumen
        this.tabsHarvesterSummaryReport = [
          {
            id: 'tab-harvester-summary',
            name: i18n.t('summary')
          }
        ];
        if (this.$store.state.app.entitySelectorItems && this.$store.state.app.entitySelectorItems.length > 0) {
          this.$store.state.app.entitySelectorItems = [];
        }
      } else if (id === 'tab-harvester-detail') {
        this.commitVisible({visible: false});
        this.reportDetailModel.loadingSpinner = true;
        this.loadingSpinner = false;
        if (detailRef) {
          detailRef.$refs['map-harvester-detail'].$refs['mapTabbedComponentTableComponent'].change();
        }
      }
      this.tabSelectedCurrentStatus = id;
    },
    /**
     * Para deshabilitar el botón del breadcrumb
     */
    disableSelectorButton() {
      return this.disableSelector;
    },
    disableExportButton(sectorKey){
     
      let enableButton = Object.values(reportToExportConstants.sectors).includes(sectorKey);
      // validar empresa
      if(enableButton){
        this.currentSector = sectorKey;
        this.addExportButton()
        this.tableComponentButtons[0].action = this.exportReportToXLS.bind(this)
        this.tableComponentButtons[0].disabled= false
        
      }else{
        this.tableComponentButtons.cleanAndUpdate([])
      }

    },

    addExportButton(){
      this.tableComponentButtons.cleanAndUpdate([])
      this.tableComponentButtons.push({
        id: 'export-pdf-report',
        tooltip: "Exportar", // CAMBIAR
        icon: 'download',
        action: ()=>{},
        selectedData: false,
        disabled: false,
      })
    },

    async exportReportToXLS(){
      let activities = [];    
      let currentActivities=[];

     switch(this.currentSector){
      case reportToExportConstants.sectors.FARM: 
        currentActivities = reportToExportConstants.farmActivities;
      break;
      case reportToExportConstants.sectors.FIELD_ENGINEERING:
        currentActivities = reportToExportConstants.engineeringActivities;
      break;
      case reportToExportConstants.sectors.HARVEST:
        currentActivities = reportToExportConstants.harvestActivities;
      break;
      default: 
        currentActivities = []
      break;
     }
     
     let data = null;
      if(currentActivities.length > 0){
        if(this.activityFilter.data.length > 0){
          activities = this.activityFilter.data.filter(activitie => currentActivities.includes(activitie.key))
        }else{
          let activitiesResponse = await activityApi.getAllForSelector(this.currentSector);
          activities = activitiesResponse.data.filter(activitie => currentActivities.includes(activitie.key))
        }
      }
      data = await machineSummaryReportService.getReportDataToExport(this.thingData, activities, this.sectorFilter.selectedData[0].key)
      
      js2excel.json2excel({
        data,
        name: "ResumenDeMaquina",
        formateDate: 'dd/mm/yyyy'
      });
    },
  
    /**
     * Incializador del selector
     */
    async initializeSelector() {
      this.selectorModel.filters.push(
        this.sectorFilter, this.activityFilter, this.typeFilter, this.workFrontFilter, this.driveUnitFilter);

      // callback cuando se seleccionan items en los filtros
      this.sectorFilter.selectAction = this.sectorFilterCallback.bind(this);
      this.activityFilter.selectAction = this.activityFilterCallback.bind(this);
      this.typeFilter.selectAction = this.thingTypeFilterCallback.bind(this);
      this.workFrontFilter.selectAction = this.disableDriveUnitFilter.bind(this);
      this.driveUnitFilter.selectAction = this.disableWorkFrontFilter.bind(this);

      // datos para el selector de sectores
      const sectorResponse = await sectorApi.getAll();
      this.sectorFilter.data.cleanAndUpdate(sectorResponse.data);

      if (this.model.sector) {
        this.sectorFilter.selectedData = this.sectorFilter.data.filter(s => s.key === this.model.sector);
        this.sectorFilter.disabled = true;
        this.sectorFilter.hide = true;
        await this.sectorFilterCallback();
      } else if (sectorResponse.data.length === 1 && sectorResponse.data[0].key === 'NO_SECTOR') {
        this.sectorFilter.selectedData = this.sectorFilter.data.filter(s => s.key === 'NO_SECTOR');
        this.sectorFilter.disabled = true;
        this.sectorFilter.hide = true;
        await this.sectorFilterCallback();
      }
      // datos para el selector de unidades de manejo
      const driveUnits = await driveUnitApi.getStructureByEnterprise();
      const hasDriveUnits = this.enterpriseHasDriveUnits(driveUnits);
      if (hasDriveUnits) {
        this.driveUnitFilter.data.cleanAndUpdate(driveUnits.structure);
      } else {
        this.driveUnitFilter.data.cleanAndUpdate([]);
        this.activityFilter.disabled = true;
        this.typeFilter.disabled = true;
        this.workFrontFilter.disabled = true;
        this.driveUnitFilter.disabled = true;
        this.selectorModel.disableGetReportButton = true;
        this.showSnackbar({
          visible: true,
          text: this.$t('driveUnits.noData'),
          timeout: 8000,
          style: SnackbarStyle.ERROR
        });
      }

      this.selectorModel.autoReportMinutes = 1;
    },
    /**
     * Callback del filtro de sector
     */
    async sectorFilterCallback() {
      if (this.sectorFilter.selectedData.length) {
        this.activityFilter.selectedData = [];
        this.activityFilter.disabled = false;
        this.typeFilter.selectedData = [];
        this.typeFilter.disabled = true;
        this.workFrontFilter.selectedData = [];
        this.workFrontFilter.disabled = true;
        // datos para el selector de actividades
        const activitiesResponse = await activityApi.getAllForSelector(this.model.sector || this.sectorFilter.selectedData[0].key);
        this.activityFilter.data.cleanAndUpdate(activitiesResponse.data);
        if (activitiesResponse.data.length === 1 && activitiesResponse.data[0].key === 'NO_ACTIVITY') {
          this.activityFilter.selectedData = this.activityFilter.data.filter(s => s.key === 'NO_ACTIVITY');
          this.activityFilter.disabled = true;
          this.activityFilter.hide = true;
          this.activityFilterCallback();
        }
      } else {
        this.activityFilter.selectedData = [];
        this.activityFilter.disabled = true;
        this.typeFilter.selectedData = [];
        this.typeFilter.disabled = true;
        this.workFrontFilter.selectedData = [];
        this.workFrontFilter.disabled = true;
      }
    },
    /**
     * Callback del filtro de actividad
     */
    async activityFilterCallback() {
      if (this.activityFilter.selectedData.length) {
        this.typeFilter.selectedData = [];
        this.typeFilter.disabled = false;
        this.workFrontFilter.selectedData = [];
        this.workFrontFilter.disabled = true;
        this.driveUnitFilter.disabled = false;
        const thingTypesResponse = await thingApi.getThingTypesByActivity(
          this.activityFilter.selectedData.map(a => a.id), this.model.sector || this.sectorFilter.selectedData[0].key
        );
        this.typeFilter.data.cleanAndUpdate(thingTypesResponse.data);
      } else {
        this.typeFilter.selectedData = [];
        this.typeFilter.disabled = true;
        this.workFrontFilter.selectedData = [];
        this.workFrontFilter.disabled = true;
        this.driveUnitFilter.disabled = false;
      }
    },
    /**
     * Callback del filtro de tipo de cosa
     */
    async thingTypeFilterCallback() {
      if (this.typeFilter.selectedData.length) {
        this.workFrontFilter.selectedData = [];
        this.workFrontFilter.disabled = !!this.driveUnitFilter.selectedData.length;
        this.driveUnitFilter.disabled = false;
        const thingTypesResponse = await thingApi.getWorkFrontsByTypes(
          this.typeFilter.selectedData.map(t => t.id), this.model.sector || this.sectorFilter.selectedData[0].key, this.activityFilter.selectedData[0].id
        );
        this.workFrontFilter.data.cleanAndUpdate(thingTypesResponse.data);
      } else {
        this.workFrontFilter.disabled = true;
        this.workFrontFilter.selectedData = [];
        this.driveUnitFilter.disabled = false;
      }
    },
    /**
     * Cargar reporte diferido
     * @param {*} reportKey
     */
    async getData(reportKey) {

      if (reportKey) {
        this.showSelector = false;
        this.disableSelector = false;
        this.disableRefreshTable = true;
        reportStorageApi.getReport(reportKey).then(async response => {
          const {data, filters} = response.data;
          this.disableExportButton(filters.sectorKey);
          const processedData = await machineSummaryReportService.processSummaryData(
            data,
            filters.timestampFrom,
            filters.timestampTo,
            filters.timeFormat,
          );
          // datos y headers
          this.setTableData(processedData);

          // datos de los filtros
          this.setFilterData(filters);
          // gráficos
          this.setChartData(processedData);
          // init charts
          this.initCharts();
        })
      } else {
        this.showSelector = true;
      }
    },
    /**
     * Setear las reglas de filtrado
     * @param {*} headers
     */
    setTableFilterRules(headers) {
      this.tableFilterRules = {};
      headers.forEach(header => this.tableFilterRules[header.value] = filterRulesMapped[header.filterType]);
    },
    /**
     * Cambiar de tab, ir al detalle de la máquina clickeada
     * @param {*} data
     */
    clickRow(data) {
      // validar id del tab actual
      if (this.tabId === 'tab-report-equipments') {
        this.setEntityConfiguration({name: this.$t('machines'), value: 'id', text: 'name'});
        const items = [];
        const duplicatedTableThings = this.tableData.map(data => ({
          id: data.thingId,
          name: data.thingName,
          type: data.typeKey
        }));
        const tableThings = [...new Set(duplicatedTableThings)];
        tableThings.forEach(thing =>
          items.push({
            id: thing.id,
            name: thing.name,
            type: thing.type
          }));
        this.setEntitySelectorItems(items);
        this.setSelectedItem({
          id: data.thingId,
          name: data.thingName,
          type: data.typeKey
        });
        if (!this.tabsHarvesterSummaryReport.find(tab => tab.id === 'tab-harvester-detail')) {
          this.tabsHarvesterSummaryReport.push({
            id: 'tab-harvester-detail',
            name: i18n.t('detail')
          });
        }
        this.commitVisible({visible: false});
        this.reportDetailModel.loadingSpinner = true;
        this.reportDetailModel.autoReport = this.autoReport;
        this.reportDetailModel.autoReportMinutes = this.autoReportMinutes;
        this.reportDetailModel.activities = this.activities.map(a => a.id);
        const driveUnits = [];
        getChildrenFromList(this.driveUnits, driveUnits);
        this.reportDetailModel.driveUnits = driveUnits.map(d => d.id);
        this.reportDetailModel.from = this.timeStampFrom;
        this.reportDetailModel.to = this.timeStampTo;
        this.loadingSpinner = false;
        this.tabSelectedHarvesterSummaryReport = 'tab-harvester-detail';
      }
    },
    /**
     * Acción del botón para actualizar los datos de la tabla
     */
    async refreshTable() {
      // clave del sector
      const sectorKey = (this.sectors.length === 1 && this.sectors[0].key) || null;

      const conversion = getDateTimeRange(this.dateAndTimeRange, this.dateAndTimeRangeCustomType,
        this.sinceDate, this.sinceTime, this.toDate, this.toTime);
      this.timeStampFrom = conversion.tFrom;
      this.timeStampTo = conversion.tTo;
      this.reportDetailModel.from = this.timeStampFrom;
      this.reportDetailModel.to = this.timeStampTo;
      if (this.driveUnits.length ) {
        this.loadingTable = true;
        if (this.loadingSpinner) {
          this.commitVisible({visible: true});
        }
        const data = await machineSummaryReportService.getReportByDriveUnits(
          this.driveUnits, sectorKey, this.activities, this.types, this.timeStampFrom, this.timeStampTo, this.timeFormat);
        const {filters} = data;
        const processedData = await machineSummaryReportService.processSummaryDataByDriveUnits(data.data, filters.from, filters.to, filters.timeFormat);
        // datos y headers
        this.setTableData(processedData, true);
        // gráficos
        this.setChartData(processedData);
        if (this.loadingSpinner) {
          this.commitVisible({visible: false});
        }
        this.loadingTable = false;
      } else if (this.things.length) {
        if (this.loadingSpinner) {
          this.commitVisible({visible: true});
        }
        this.loadingTable = true;
        const data = await machineSummaryReportService.getReportByThings(
          this.things, this.activities, this.timeStampFrom, this.timeStampTo, this.timeFormat);
        const {filters} = data;
        const processedData = await machineSummaryReportService.processSummaryDataByThings(
          data.data, filters.from, filters.to, filters.timeFormat);
        // datos y headers
        this.setTableData(processedData, true);
        // gráficos
        this.setChartData(processedData);
        if (this.loadingSpinner) {
          this.commitVisible({visible: false});
        }
        this.loadingTable = false;
      }
    },
    /**
     * Obtener reporte
     * @param {*} eventData
     */
    async getReport(eventData) {
      // guardo los datos elegidos en los selectores
      this.filterSwitch = eventData.filterSwitchValue;
      if (eventData.filterSwitchValue) {
        if (this.sectorFilter.hide) {
          this.sectors.cleanAndUpdate(this.sectorFilter.selectedData);
        } else {
          this.sectors.clean();
        }
        if (this.activityFilter.hide) {
          this.activities.cleanAndUpdate(this.activityFilter.selectedData);
        } else {
          this.activities.clean();
        }
        this.types.clean();
        this.driveUnits.clean();
        this.things = [];
        getChildrenFromList(eventData.groupEquipmentFilterSelected, this.things);
      } else {
        this.sectors.cleanAndUpdate(this.sectorFilter.selectedData);
        this.activities.cleanAndUpdate(this.activityFilter.selectedData);
        this.types.cleanAndUpdate(this.typeFilter.selectedData);
        this.things = [];
        getChildrenFromList(this.workFrontFilter.selectedData, this.things);
        this.driveUnits = [];
        getChildrenFromList(this.driveUnitFilter.selectedData, this.driveUnits);
      }

      this.dateAndTimeRange = eventData.dateAndTimeRange;
      this.dateAndTimeRangeCustomType = eventData.dateAndTimeRangeCustomType;
      this.sinceDate = eventData.sinceDate;
      this.sinceTime = eventData.sinceTime;
      this.toDate = eventData.toDate;
      this.toTime = eventData.toTime;
      this.autoReport = eventData.autoReport;
      this.autoReportMinutes = eventData.autoReportMinutes;
      this.autoReportName = eventData.autoReportName;

      // clave del sector
      const sectorKey = (this.sectors.length === 1 && this.sectors[0].key) || null

      const conversion = getDateTimeRange(this.dateAndTimeRange, this.dateAndTimeRangeCustomType,
        this.sinceDate, this.sinceTime, this.toDate, this.toTime);
      this.timeStampFrom = conversion.tFrom;
      this.timeStampTo = conversion.tTo;
      this.timeFormat = eventData.dateAndTimeFormat;
      this.reportDetailModel.from = this.timeStampFrom;
      this.reportDetailModel.to = this.timeStampTo;

      let report;
      const route = this.$route.path;
      const queryParams = this.$route.query;
      if (this.driveUnits.length) {
        if (eventData.autoReport) {
          this.loadingTable = true;
          this.disableRefreshTable = false;
          if (this.loadingSpinner) {
            this.commitVisible({visible: true});
          }
          // datos para el reporte
          report = await machineSummaryReportService.getAutoReportByDriveUnits(
            this.driveUnits, this.sectors, this.types, this.activities, this.timeStampFrom, this.timeStampTo,
            this.sinceDate, this.sinceTime, this.toDate, this.toTime, this.dateAndTimeRange, this.dateAndTimeRangeCustomType,
            this.timeFormat, this.filterSwitch, route, eventData.autoReportMinutes, eventData.autoReportName, null, queryParams);

          const processedData = await machineSummaryReportService.processSummaryData(
            report.data, this.timeStampFrom, this.timeStampTo, this.timeFormat  // , statesTypesByEnterprise
          );
          this.disableExportButton(report.data.filters.sectorKey);
          // id del reporte guardado en la bandeja temporal
          this.autoReportId = report.id;
          // datos y headers
          this.setTableData(processedData);
          // gráficos
          this.setChartData(processedData);
          // init charts
          this.initCharts();
          if (this.loadingSpinner) {
            this.commitVisible({visible: false});
          }
          this.loadingTable = false;
          this.setReportDetails();
          // auto-reporte
          this.autoReportByDriveUnits();
        } else {
          machineSummaryReportService.getReportByDriveUnitsDeferred(
            this.driveUnits, sectorKey, this.types, this.activities, this.timeStampFrom, this.timeStampTo,
            this.sinceDate, this.sinceTime, this.toDate, this.toTime,
            this.dateAndTimeRange, this.dateAndTimeRangeCustomType, route, this.timeFormat, this.filterSwitch
          );
          this.showReportDefer({updateFunction: this.getData.bind(this)});
        }
      } else if (this.things.length) {
        if (eventData.autoReport) {
          if (this.loadingSpinner) this.commitVisible({visible: true});
          this.loadingTable = true;
          this.disableRefreshTable = false;
          // datos para el reporte
          report = await machineSummaryReportService.getAutoReportByThings(
            this.things, this.sectors, this.types, this.activities, this.timeStampFrom, this.timeStampTo,
            this.sinceDate, this.sinceTime, this.toDate, this.toTime, this.dateAndTimeRange, this.dateAndTimeRangeCustomType,
            this.timeFormat, this.filterSwitch, route, eventData.autoReportMinutes, eventData.autoReportName, null, queryParams
          );

          const processedData = await machineSummaryReportService.processSummaryData(
            report.data, this.timeStampFrom, this.timeStampTo, this.timeFormat
          );
          // id del reporte guardado en la bandeja temporal
          this.autoReportId = report.id;
          // datos y headers
          this.setTableData(processedData);
          // gráficos
          this.setChartData(processedData);
          // init charts
          this.initCharts();
          if (this.loadingSpinner) {
            this.commitVisible({visible: false});
          }
          this.loadingTable = false;
          // detalles
          this.setReportDetails();
          // auto-reporte
          this.autoReportByThings();
        } else {
          machineSummaryReportService.getReportByThingsDeferred(
            this.things, sectorKey, this.types, this.activities, this.timeStampFrom, this.timeStampTo,
            this.sinceDate, this.sinceTime, this.toDate, this.toTime,
            this.dateAndTimeRange, this.dateAndTimeRangeCustomType, route, this.timeFormat, this.filterSwitch
          );
          this.showReportDefer({updateFunction: this.getData.bind(this)});
        }
      }
      this.showSelector = false;
    },
    /**
     * Auto-reporte generado por unidades de manejo
     */
    autoReportByDriveUnits() {
      clearInterval(this.intervalId);

      let report;
      // ruta
      const route = this.$route.path;
      this.intervalId = setInterval(
        async () => {
          const conversion = getDateTimeRange(this.dateAndTimeRange, this.dateAndTimeRangeCustomType,
            this.sinceDate, this.sinceTime, this.toDate, this.toTime);
          this.timeStampFrom = conversion.tFrom;
          this.timeStampTo = conversion.tTo;
          this.reportDetailModel.from = this.timeStampFrom;
          this.reportDetailModel.to = this.timeStampTo;
          if (this.loadingSpinner) {
            this.commitVisible({visible: true});
          }
          this.loadingTable = true;
          // datos para el reporte
          report = await machineSummaryReportService.getAutoReportByDriveUnits(
            this.driveUnits, this.sectors, this.types, this.activities, this.timeStampFrom, this.timeStampTo,
            this.sinceDate, this.sinceTime, this.toDate, this.toTime, this.dateAndTimeRange, this.dateAndTimeRangeCustomType,
            this.timeFormat, this.filterSwitch, route, this.autoReportMinutes, this.autoReportName, this.autoReportId
          );

          const processedData = await machineSummaryReportService.processSummaryData(
            report.data, this.timeStampFrom, this.timeStampTo, this.timeFormat
          );
          // datos y headers
          this.setTableData(processedData, true);
          // gráficos
          this.setChartData(processedData);
          if (this.loadingSpinner) {
            this.commitVisible({visible: false});
          }
          this.loadingTable = false;
        },
        this.autoReportMinutes * 60 * 1000)
    },
    /**
     * Auto-reporte generado por cosas
     */
    autoReportByThings() {
      clearInterval(this.intervalId);

      let report;
      // ruta
      const route = this.$route.path
      this.intervalId = setInterval(
        async () => {
          const conversion = getDateTimeRange(this.dateAndTimeRange, this.dateAndTimeRangeCustomType,
            this.sinceDate, this.sinceTime, this.toDate, this.toTime);
          this.timeStampFrom = conversion.tFrom;
          this.timeStampTo = conversion.tTo;
          this.reportDetailModel.from = this.timeStampFrom;
          this.reportDetailModel.to = this.timeStampTo;
          if (this.loadingSpinner) {
            this.commitVisible({visible: true});
          }
          this.loadingTable = true;
          // datos para el reporte
          report = await machineSummaryReportService.getAutoReportByThings(
            this.things, this.sectors, this.types, this.activities, this.timeStampFrom, this.timeStampTo,
            this.sinceDate, this.sinceTime, this.toDate, this.toTime, this.dateAndTimeRange, this.dateAndTimeRangeCustomType,
            this.timeFormat, this.filterSwitch, route, this.autoReportMinutes, this.autoReportName, this.autoReportId
          );

          const processedData = await machineSummaryReportService.processSummaryData(
            report.data, this.timeStampFrom, this.timeStampTo, this.timeFormat
          );
          // datos y headers
          this.setTableData(processedData, true);
          // gráficos
          this.setChartData(processedData);
          if (this.loadingSpinner) {
            this.commitVisible({visible: false});
          }
          this.loadingTable = false;
        },
        this.autoReportMinutes * 60 * 1000)
    },
    /**
     * Detalles del reporte
     */
    setReportDetails() {
      const detailData = {
        from: this.timeStampFrom,
        to: this.timeStampTo,
        activities: this.activities,
        types: this.types
      };
      const dataStructure = [
        {detailDataProp: 'from', propTitle: this.$t('since'), propType: 'DATE'},
        {detailDataProp: 'to', propTitle: this.$t('to'), propType: 'DATE'},
        {
          detailDataProp: 'activities',
          propTitle: this.$t('activity'),
          propType: 'LIST',
          propRefs: {list: this.activities, value: 'id', text: 'name'}
        },
        {
          detailDataProp: 'types',
          propTitle: this.$t('headers.thingType'),
          propType: 'LIST',
          propRefs: {list: this.types, value: 'id', text: 'name'}
        }
      ];
      if (this.driveUnits.length) {
        detailData.driveUnits = this.driveUnits;
        dataStructure.push({
          detailDataProp: 'driveUnits',
          propTitle: this.$t('driveUnitsFilter'),
          propType: 'LIST',
          propRefs: {list: this.driveUnits, value: 'id', text: 'name'}
        });
      } else {
        detailData.things = this.things;
        dataStructure.push({
          detailDataProp: 'things',
          propTitle: this.$t('things'),
          propType: 'LIST',
          propRefs: {list: this.things, value: 'id', text: 'name'}
        });
      }
      this.setDetails({
        detailData,
        dataStructure
      });
    },
    /**
     * Cargar los datos del selector
     * @param {*} filterData
     */
    setFilterData(filterData) {
      // switch de tipo de filtrado
      this.selectorModel.filterSwitch.value = filterData.filterSwitch;
      this.filterSwitch = filterData.filterSwitch;

      if (filterData.sectorKey != null) {
        const sector = this.sectorFilter.data.find(sector => sector.key === filterData.sectorKey);
        if (sector) {
          this.sectors = [sector];
          this.sectorFilter.selectedData = [sector];
        }
      }
      this.activityFilter.selectedData = filterData.activities ? filterData.activities : [];
      this.activityFilterCallback();
      this.typeFilter.selectedData = filterData.types ? filterData.types : [];
      this.thingTypeFilterCallback();
      this.driveUnitFilter.selectedData = filterData.driveUnits ? filterData.driveUnits : [];

      if (this.filterSwitch) {
        this.selectorModel.groupEquipmentFilter.selectedData.cleanAndUpdate(filterData.things ? filterData.things : []);
      } else {
        this.workFrontFilter.selectedData.cleanAndUpdate(filterData.things ? filterData.things : []);
      }
      this.timeStampFrom = filterData.timestampFrom;
      this.timeStampTo = filterData.timestampTo;
      this.selectorModel.selectedDateAndTimeRange = filterData.dateTimeRange;
      this.selectorModel.selectedDateAndTimeRangeCustomType = filterData.customDateTimeRangeType;
      this.selectorModel.sinceDate = filterData.sinceDate;
      this.selectorModel.sinceTime = filterData.sinceTime;
      this.selectorModel.toDate = filterData.toDate;
      this.selectorModel.toTime = filterData.toTime;
      this.selectorModel.selectedTimeFormat = filterData.timeFormat;

      // auto-reporte
      if (filterData.autoReport != null) {
        this.selectorModel.autoReport = filterData.autoReport;
      }
      if (filterData.autoReportMinutes != null) {
        this.selectorModel.autoReportMinutes = filterData.autoReportMinutes;
      }
      this.selectorModel.autoReportName = null;

      // back-up de datos del selector
      // sectores
      if (filterData.sectors) {
        this.sectorFilter.selectedData = filterData.sectors;
        this.sectors.cleanAndUpdate(filterData.sectors);
      } else {
        this.sectors = [];
      }
      // actividades
      this.activities.cleanAndUpdate(filterData.activities);
      // tipos
      this.types.cleanAndUpdate(filterData.types);
      // cosas
      if (filterData.things) {
        this.things.cleanAndUpdate(filterData.things);
      } else {
        this.things = [];
      }
      // unidades de manejo
      if (filterData.driveUnits) {
        this.driveUnits.cleanAndUpdate(filterData.driveUnits);
      } else {
        this.driveUnits = [];
      }
      // selectores de rangos de tiempo y auto-reporte
      this.timeFormat = filterData.timeFormat;
      this.dateAndTimeRange = filterData.dateTimeRange;
      this.dateAndTimeRangeCustomType = filterData.customDateTimeRangeType;
      this.sinceDate = filterData.sinceDate;
      this.sinceTime = filterData.sinceTime;
      this.toDate = filterData.toDate;
      this.toTime = filterData.toTime;
      this.autoReportMinutes = filterData.autoReportMinutes;
      this.autoReportName = filterData.autoReportName;

      this.setCustomDateAndTimeFilter(filterData);
      this.setReportDetails();
      this.validateSelector();
    },
    setCustomDateAndTimeFilter(filterData) {
      if (filterData.timestampFrom && filterData.timestampTo) {
        const sinceDateData = ISODateFromUnixTimestamp(filterData.timestampFrom, true);
        const toDateData = ISODateFromUnixTimestamp(filterData.timestampTo, true);

        this.selectorModel.sinceDate = sinceDateData.date;
        this.selectorModel.toDate = toDateData.date;

        if (this.selectorModel.selectedDateAndTimeRange !== TimeRanges.CUSTOM) {
          this.selectorModel.selectedDateAndTimeRange = TimeRanges.CUSTOM;
          this.selectorModel.selectedDateAndTimeRangeCustomType = CustomTimeRangeTypes.DATE_AND_TIME;
          this.selectorModel.sinceTime = sinceDateData.time;
          this.selectorModel.toTime = toDateData.time;
        } else if (this.selectorModel.selectedDateAndTimeRange === TimeRanges.CUSTOM &&
          this.selectorModel.selectedDateAndTimeRangeCustomType === CustomTimeRangeTypes.DATE) {
          this.selectorModel.sinceTime = null;
          this.selectorModel.toTime = null;
        } else {
          this.selectorModel.sinceTime = sinceDateData.time;
          this.selectorModel.toTime = toDateData.time;
        }
      }
    },
    disableWorkFrontFilter() {
      if (this.driveUnitFilter.selectedData.length) {
        this.workFrontFilter.disabled = true;
        this.workFrontFilter.selectedData.clean();
      } else if (this.typeFilter.selectedData.length) {
        this.workFrontFilter.disabled = false;
      }
    },
    disableDriveUnitFilter() {
      if (this.workFrontFilter.selectedData.length) {
        this.driveUnitFilter.disabled = true;
        this.driveUnitFilter.selectedData.clean();
      } else {
        this.driveUnitFilter.disabled = false;
      }
    },
    validateSelector() {
      Vue.nextTick(() => {
        const thingSelected = this.selectorModel.groupEquipmentFilter.selectedData;
        const activitySelected = this.activityFilter.selectedData;
        const thingTypeSelected = this.typeFilter.selectedData;
        const workFrontSelected = this.workFrontFilter.selectedData;
        const driveUnitSelected = this.driveUnitFilter.selectedData;
        const customTimeValidation = selectorDateTimeValidation(this.selectorModel.selectedDateAndTimeRange,
          this.selectorModel.selectedDateAndTimeRangeCustomType, this.selectorModel.customDateTimeValidForm,
          this.selectorModel.sinceDate, this.selectorModel.sinceTime, this.selectorModel.toDate, this.selectorModel.toTime
        );
        const timeFormatSelected = this.selectorModel.selectedTimeFormat;
        this.selectorModel.disableGetReportButton =
          (this.selectorModel.showGroupEquipmentFilter && this.selectorModel.filterSwitch.value && thingSelected.length === 0)
          || ((!this.selectorModel.showGroupEquipmentFilter || !this.selectorModel.filterSwitch.value)
            && (activitySelected.length === 0 || thingTypeSelected.length === 0
              || (workFrontSelected.length === 0 && driveUnitSelected.length === 0)))
          || customTimeValidation
          || !timeFormatSelected;
      })
    },
    /**
     * Validar que una empresa tenga unidades de manejos
     */
    enterpriseHasDriveUnits(driveUnits) {
      let hasDriveUnits = false;
      if (driveUnits && driveUnits.structure) {
        const structureDriveUnits = [];
        getChildrenFromList(driveUnits.structure, structureDriveUnits);
        hasDriveUnits = !!structureDriveUnits.length;
      }
      return hasDriveUnits;
    },
    /**
     * Actualizar los datos de los gráficos del reporte
     * @param {*} data
     */
    setChartData(data) {
      this.showPlantationAvailableTimeChart = data.availableTimeCharts.includePlantationAvailableTime;

      // gráficos de tiempo en cada estado
      this.timeByState.thingCharts.cleanAndUpdate(data.timeByStateCharts.thingCharts);
      this.timeByState.things.cleanAndUpdate(data.timeByStateCharts.things);
      this.timeByState.driverCharts.cleanAndUpdate(data.timeByStateCharts.driverCharts);
      this.timeByState.drivers.cleanAndUpdate(data.timeByStateCharts.drivers);
      this.timeByState.workFrontCharts.cleanAndUpdate(data.timeByStateCharts.workFrontCharts);
      this.timeByState.workFronts.cleanAndUpdate(data.timeByStateCharts.workFronts);

      // gráficos de tiempo disponible
      this.availableTime.thingCharts.cleanAndUpdate(data.availableTimeCharts.thingCharts);
      this.availableTime.things.cleanAndUpdate(data.availableTimeCharts.things);
      this.availableTime.driverCharts.cleanAndUpdate(data.availableTimeCharts.driverCharts);
      this.availableTime.drivers.cleanAndUpdate(data.availableTimeCharts.drivers);
      this.availableTime.workFrontCharts.cleanAndUpdate(data.availableTimeCharts.workFrontCharts);
      this.availableTime.workFronts.cleanAndUpdate(data.availableTimeCharts.workFronts);

      // gráficos de tiempo disponible de plantación
      if (data.availableTimeCharts.includePlantationAvailableTime) {
        this.plantationAvailableTime.thingCharts.cleanAndUpdate(data.availableTimeCharts.plantationThingCharts);
        this.plantationAvailableTime.things.cleanAndUpdate(data.availableTimeCharts.plantationThings);
        this.plantationAvailableTime.driverCharts.cleanAndUpdate(data.availableTimeCharts.plantationDriverCharts);
        this.plantationAvailableTime.drivers.cleanAndUpdate(data.availableTimeCharts.plantationDrivers);
        this.plantationAvailableTime.workFrontCharts.cleanAndUpdate(data.availableTimeCharts.plantationWorkFrontCharts);
        this.plantationAvailableTime.workFronts.cleanAndUpdate(data.availableTimeCharts.plantationWorkFronts);
      } else {
        this.plantationAvailableTime = {
          thingCharts: [],
          things: [],
          driverCharts: [],
          drivers: [],
          workFrontCharts: [],
          workFronts: []
        };
      }
    },
    /**
     * Inicializar gráficos
     */
    initCharts() {
      Vue.nextTick(() => {
        this.timeByStateDonutChartContainerModel.initialize = true;
        this.availableTimeDonutChartContainerModel.initialize = true;
        this.plantationAvailableTimeDonutChartContainerModel.initialize = true;
      });
    },
    /**
     * Setear los datos y headers para las tablas
     * @param {*} data
     * @param {*} update
     */
    setTableData(data, update = false) {
      // headers
      this.thingHeaders.cleanAndUpdate(data.thingHeaders);
      this.workFrontHeaders.cleanAndUpdate(data.workFrontHeaders);
      this.driverHeaders.cleanAndUpdate(data.driverHeaders);
      // datos
      this.thingData.cleanAndUpdate(data.things);
      this.driverData.cleanAndUpdate(data.drivers);
      this.workFrontData.cleanAndUpdate(data.workFronts);

      // cargo los datos del tab actual
      this.tabClicked(this.tabId, update);
    },
    /**
     * Cambiar los datos y los headers de la tabla cuando se cambia de tab
     * @param {*} id
     * @param {*} update
     */
    tabClicked(id, update = false) {
      this.tabId = id;
      switch (id) {
        case 'tab-report-workfront':
          this.tableData.cleanAndUpdate(this.workFrontData);
          if (!update) {
            this.tableHeaders.cleanAndUpdate(this.workFrontHeaders);
            this.setTableFilterRules(this.workFrontHeaders);
          }
          break;
        case 'tab-report-hr':
          this.tableData.cleanAndUpdate(this.driverData);
          if (!update) {
            this.tableHeaders.cleanAndUpdate(this.driverHeaders);
            this.setTableFilterRules(this.driverHeaders);
          }
          break;
        case 'tab-report-equipments':
          this.tableData.cleanAndUpdate(this.thingData);
          if (!update) {
            this.tableHeaders.cleanAndUpdate(this.thingHeaders);
            this.setTableFilterRules(this.thingHeaders);
          }
          break;
      }
    },
    /**
     * Carga inicial del reporte, este método se ejecuta en el hook mounted para decidir
     * si el reporte debe cargarse de la base de datos (diferido) o es un autorreporte
     */
    initialReportLoad() {
      // obtener los datos de la url
      const queryValidation = autoReportService.autoReportRouteQueryValidation(this.$route.query);
      switch (queryValidation.type) {
        // diferido
        case 'DEFERRED':
          this.getData(queryValidation.key);
          break;
        // auto-reporte
        case 'AUTO_REPORT':
          this.getAutoReportData(queryValidation.autoReportId);
          break;
        // reporte en blanco
        case 'EMPTY':
        default:
          this.showSelector = true;
      }
    },
    /**
     * Obtener datos del autorreporte y cargarlo
     * @param {*} reportId
     */
    async getAutoReportData(reportId) {
      this.loadingTable = true;
      this.showSelector = false;
      this.disableSelector = false;
      this.disableRefreshTable = false;
      const report = await autoReportService.getReportFromDB(reportId);
      if (report) {
        const {data, filters} = report;

        const processedData = await machineSummaryReportService.processSummaryData(
          data, filters.from, filters.to, filters.timeFormat
        );
        this.autoReportId = report.id;
        // datos y headers
        this.setTableData(processedData);
        // datos de los filtros
        this.setFilterData(filters);
        // gráficos
        this.setChartData(processedData);
        // init charts
        this.initCharts();

        // lanzo la actualización
        if (this.driveUnits.length) {
          this.autoReportByDriveUnits();
        } else if (this.things.length) {
          this.autoReportByThings();
        }
      } else {
        this.showSelector = true;
      }
      this.loadingTable = false;
    }
  },
  watch: {
    selectorModel: {
      handler: function () {
        this.validateSelector();
      },
      deep: true
    }
  }
}
