import DashboardChartContainer from '@/components/commons/dashboard-chart-container/DashboardChartContainer.vue'
import i18n from '@/i18n'
import Vue from 'vue'

export default {
  name: 'DonutChartContainer',
  components: {
    DashboardChartContainer
  },
  model: {
    prop: 'model'
  },
  props: {
    // modelo
    model: {
      type: Object,
      required: true
    },
    // ID para el componente DashboardChartContainer
    dashboardChartContainerId: {
      type: String,
      required: true
    },
    // ID para el componente de los gráficos
    chartComponentId: {
      type: String,
      required: true
    },
    // título (nombre) que se muestra en la primera línea del componente
    title: {
      type: String,
      required: true
    },
    // nombre del reporte en el que se encuentra el gráfico
    reportName: {
      type: String,
      required: true
    },
    // nombre del archivo para exportarlo
    exportFilename: {
      type: String,
      required: true
    },
    /**
     * Arreglo de gráficos por cosas
     */
    thingCharts: {
      type: Array,
      required: true
    },
    /**
     * Arreglo de cosas para el selector
     */
    thingSelectorData: {
      type: Array,
      required: true
    },
    /**
     * Arreglo de gráficos por conductor
     */
    driverCharts: {
      type: Array,
      required: true
    },
    /**
     * Arreglo de conductores para el selector
     */
    driverSelectorData: {
      type: Array,
      required: true
    },
    /**
     * Arreglo de gráficos por fretnes de trabajo
     */
    workFrontCharts: {
      type: Array,
      required: true
    },
    /**
     * Arreglo de frentes de trabajo para el selector
     */
    workFrontSelectorData: {
      type: Array,
      required: true
    },
    detailsExtra: {
      type: String,
      required: true
    }
  },
  data: () => ({
    chartContainerModel: {
      // para mostrar/ocultar los filtros
      showNavigationPanel: false,
      // rangos de tiempo
      dateAndTimeRanges: [],
      //  tipos de rangos personalizados
      dateAndTimeCustomRangeTypes: [],
      selectedDateAndTimeRange: null,
      customDateTimeValidForm: false,
      selectedDateAndTimeRangeCustomType: null,
      sinceDate: null,
      sinceTime: null,
      toDate: null,
      toTime: null,
      filters: [],
      selects: [],
      percentageSwitchModel: false,
      selectorData: [],
      selectorModel: null,
      charts: [],
      disableSaveButton: false,
      update: false
    },
    // arreglos de datos de los selectores(máquinas, frentes de trabajo, rrhh)
    things: [],
    drivers: [],
    workFronts: [],
    // valores seleccionados
    thingSelected: null,
    driverSelected: null,
    workFrontSelected: null,
    currentChartIndex: 0,
    chartName: '',
    // para la opción "todos" de los selectores
    all: {
      id: 'ALL',
      name: i18n.t('all')
    },
    loading: false
  }),
  async mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale

    // si hay valores para los selectores, los seteo
    this.things.cleanAndUpdate(this.thingSelectorData)
    this.workFronts.cleanAndUpdate(this.workFrontSelectorData)
    this.drivers.cleanAndUpdate(this.driverSelectorData)
  },
  computed: {
    /**
     * Detalles del gráfico (html)
     */
    details () {
      let details = `<h3>${this.$t('report')}: ${this.reportName}</h3>`
      details += `<h4>${this.$t('chart.chart')}: ${this.title}</h4>`
      let entityName = this.$t('all')
      if (this.chartContainerModel.selectorModel != null && this.chartContainerModel.selectorModel !== 'ALL') {
        const entitySelected = this.chartContainerModel.selectorData.find(element => element.id === this.chartContainerModel.selectorModel)
        entityName = entitySelected != null && entitySelected.name != null ? entitySelected.name : null
      }
      if (this.chartName) {
        details += `<h4>${this.$t('level')}: ${this.chartName} ${entityName != null ? `(${entityName})` : ''}</h4>`
      }
      if (this.detailsExtra) {
        details += this.detailsExtra
      }
      return details
    }
  },
  methods: {
    /**
     * Para filtrar los datos del gráfico de acuerdo al selector
     * @param {*} index
     */
    filterCurrentChart (index) {
      if (this.chartContainerModel.charts.length > 0) {
        const charts = this.getChartsArray(index)
        let chartData
        if (!this.chartContainerModel.selectorModel || this.chartContainerModel.selectorModel === 'ALL') {
          chartData = charts.find(chart => chart.id === 'ALL')
          this.chartContainerModel.charts[index] = chartData.chart
        } else {
          chartData = charts.find(chart => chart.id === this.chartContainerModel.selectorModel)
          this.chartContainerModel.charts[index] = chartData.chart
        }

        // actualización de los gráficos creados
        this.updateChartComponent()
      }
    },
    /**
     * Función ejecutada cuando se cambia de tab (se cambia de gráfico)
     * @param {*} data
     */
    changeChart (data) {
      if (data.index !== this.currentChartIndex) {
        // nombre del gráfico (para los detalles)
        this.chartName = this.chartContainerModel.charts[data.index].name
        // cambio el valor del selector y guardo el valor para el gráfico actual
        this.chartContainerModel.selectorData.cleanAndUpdate(this.getSelectorData(data.index))
        // currentChart aún tiene los valores del gráfico anterior, guardo el valor
        this.setSelectorModel(this.currentChartIndex, this.chartContainerModel.selectorModel)
        // actualizo el valor de currentChart
        this.currentChartIndex = data.index
        // actualización del gráfico
        if (this.chartContainerModel.selectorModel !== this.getSelectorModel(data.index)) {
          // si hay valor seleccionado, lo seteo
          this.chartContainerModel.selectorModel = this.getSelectorModel(data.index)
        } else {
          this.updateChart()
        }
      }
    },
    /**
     * Obtener el arreglo de items del selector para el gráfico actual
     * @param {*} index
     */
    getSelectorData (index) {
      switch (index) {
        case 0:
          return this.things
        case 1:
          return this.workFronts
        case 2:
          return this.drivers
        default:
          console.warn('El gráfico no existe')
          return []
      }
    },
    /**
     * Setear el valor seleccionado en el selector de entidades en el estado del componente
     * @param {*} index
     * @param {*} value
     */
    setSelectorModel (index, value) {
      switch (index) {
        case 0:
          this.thingSelected = value
          break
        case 1:
          this.workFrontSelected = value
          break
        case 2:
          this.driverSelected = value
          break
        default:
          console.warn('El gráfico no existe')
      }
    },
    /**
     * Obtener el valor de un modelo guardado en la base de datos
     * @param {*} index
     * @returns
     */
    getSelectorModel (index) {
      switch (index) {
        case 0:
          return this.thingSelected
        case 1:
          return this.workFrontSelected
        case 2:
          return this.driverSelected
        default:
          console.warn('El gráfico no existe')
          return null
      }
    },
    /**
     * Obtener arreglo de gráficos para la pestaña a
     * @param {*} index
     */
    getChartsArray (index) {
      switch (index) {
        case 0:
          return this.thingCharts
        case 1:
          return this.workFrontCharts
        case 2:
          return this.driverCharts
        default:
          console.warn('El gráfico no existe')
          return []
      }
    },
    /**
     * Para actualizar el gráfico actual cuando se usa el selector
     */
    async updateChart () {
      this.loading = true

      // filtra los datos del gráfico actual de acuerdo a la selección
      this.filterCurrentChart(this.currentChartIndex)

      this.loading = false
    },
    /**
     * Actualizar ChartComponent
     */
    updateChartComponent () {
      /*
        SOLUCIÓN PROVISORIA PARA LA ACTUALIZACIÓN DE GRÁFICOS:
        CUANDO SE IMPLEMETE EL MODELO EN EL COMPONENTE GENÉRICO, LA ACTUALIZACIÓN SE REALIZARÁ DE FORMA INMEDIATA
        CUANDO CAMBIEN LOS DATOS DEL GRÁFICO.
        Por ahora se definió un variable booleana del tipo "semáforo", es decir, cuando está en true, ejecuta la actualización
      */
      this.chartContainerModel.update = true
      const that = this
      Vue.nextTick(function () {
        that.chartContainerModel.update = false
      })
    }
  },
  watch: {
    'chartContainerModel.selectorModel': {
      handler: function () {
        this.updateChart()
        switch (this.currentChartIndex) {
          case 0:
            this.thingSelected = this.chartContainerModel.selectorModel
            break
          case 1:
            this.workFrontSelected = this.chartContainerModel.selectorModel
            break
          case 2:
            this.driverSelected = this.chartContainerModel.selectorModel
            break
          default:
            console.warn('El gráfico no existe')
        }
      },
      deep: true
    },
    thingSelectorData: {
      handler: function (newValues) {
        this.things.cleanAndUpdate(newValues)
        if (this.thingSelected != null) {
          const currentSelectedValidation = newValues.find(element => element.id === this.thingSelected)
          if (currentSelectedValidation == null) {
            this.thingSelected = null
            if (this.currentChartIndex === 0) {
              this.chartContainerModel.selectorModel = null
            }
          }
        }
      },
      deep: true
    },
    workFrontSelectorData: {
      handler: function (newValues) {
        this.workFronts.cleanAndUpdate(newValues)
        if (this.workFrontSelected != null) {
          const currentSelectedValidation = newValues.find(element => element.id === this.workFrontSelected)
          if (currentSelectedValidation == null) {
            this.workFrontSelected = null
            if (this.currentChartIndex === 1) {
              this.chartContainerModel.selectorModel = null
            }
          }
        }
      },
      deep: true
    },
    driverSelectorData: {
      handler: function (newValues) {
        this.drivers.cleanAndUpdate(newValues)
        if (this.driverSelected != null) {
          const currentSelectedValidation = newValues.find(element => element.id === this.driverSelected)
          if (currentSelectedValidation == null) {
            this.driverSelected = null
            if (this.currentChartIndex === 2) {
              this.chartContainerModel.selectorModel = null
            }
          }
        }
      },
      deep: true
    },
    'model.initialize': {
      handler: function (value) {
        if (value) {
          this.chartContainerModel.charts.cleanAndUpdate([
            this.thingCharts[0].chart,
            this.workFrontCharts[0].chart,
            this.driverCharts[0].chart
          ])
          this.chartContainerModel.selectorData.cleanAndUpdate(this.thingSelectorData)
          this.chartName = this.chartContainerModel.charts[0].name
          this.model.initialize = false
          this.updateChartComponent()
        }
      }
    }
  }
}
