import { FilterType, DataType, CellType, SerieType } from '@colven/common-domain-lib/lib'
import i18n from '@/i18n'

const summaryHeadersThings = (lang) => {
  return {
    things: [
      {
        text: i18n.t('machineSummaryReport.HEADERS.workFront', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'workFrontName',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.machine', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'thingName',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.activity', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'currentActivityName',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.availableTime', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'availableTime',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.plantationAvailableTime', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'plantationAvailableTime',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.productiveTime', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'productiveTime',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.improductiveTime', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'improductiveTime',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.totalTime', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'totalStatesTime',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.statesAverageSpeed', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'statesAverageSpeed',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.workingStateAverageSpeed', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'workingStateAverageSpeed',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.maxSpeed', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'maxSpeed',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.distance', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'distance',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.averageRPM', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'averageRPM',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.tonsPerDay', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'tonsPerDay',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.tonsPerHour', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'tonsPerHour',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.TCHP', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'TCHP',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.anomalies', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'anomalies',
        width: 200
      }
    ],
    drivers: [
      {
        text: i18n.t('machineSummaryReport.HEADERS.rrhh', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'driverName',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.driverCode', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'driverCode',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.availableTime', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'availableTime',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.plantationAvailableTime', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'plantationAvailableTime',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.productiveTime', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'productiveTime',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.improductiveTime', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'improductiveTime',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.totalTime', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'totalStatesTime',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.statesAverageSpeed', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'statesAverageSpeed',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.workingStateAverageSpeed', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'workingStateAverageSpeed',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.maxSpeed', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'maxSpeed',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.distance', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'distance',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.averageRPM', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'averageRPM',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.tonsPerDay', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'tonsPerDay',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.tonsPerHour', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'tonsPerHour',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.TCHP', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'TCHP',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.anomalies', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'anomalies',
        width: 200
      }
    ],
    workFronts: [
      {
        text: i18n.t('machineSummaryReport.HEADERS.workFront', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'workFrontName',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.availableTime', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'availableTime',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.plantationAvailableTime', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'plantationAvailableTime',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.productiveTime', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'productiveTime',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.improductiveTime', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'improductiveTime',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.totalTime', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'totalStatesTime',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.statesAverageSpeed', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'statesAverageSpeed',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.workingStateAverageSpeed', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'workingStateAverageSpeed',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.maxSpeed', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'maxSpeed',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.distance', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'distance',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.averageRPM', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'averageRPM',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.tonsPerDay', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'tonsPerDay',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.tonsPerHour', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'tonsPerHour',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.TCHP', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'TCHP',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.anomalies', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'anomalies',
        width: 200
      }
    ]
  }
}

const summaryHeadersDriveUnits = (lang) => {
  return {
    things: [
      {
        text: i18n.t('machineSummaryReport.HEADERS.workFront', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'workFrontName',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.machine', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'thingName',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.activity', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'currentActivityName',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.driveUnit', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'driveUnitName',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.TCHP', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'TCHP',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.TCHR', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'TCHR',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.harvestSpeed', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'harvestSpeed',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.availableTime', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'availableTime',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.plantationAvailableTime', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'plantationAvailableTime',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.productiveTime', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'productiveTime',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.improductiveTime', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'improductiveTime',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.totalTime', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'totalStatesTime',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.statesAverageSpeed', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'statesAverageSpeed',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.workingStateAverageSpeed', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'workingStateAverageSpeed',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.maxSpeed', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'maxSpeed',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.distance', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'distance',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.averageRPM', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'averageRPM',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.tonsPerDay', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'tonsPerDay',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.tonsPerHour', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'tonsPerHour',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.anomalies', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'anomalies',
        width: 200
      }
    ],
    drivers: [
      {
        text: i18n.t('machineSummaryReport.HEADERS.rrhh', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'driverName',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.driverCode', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'driverCode',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.driveUnit', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'driveUnitName',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.TCHP', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'TCHP',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.TCHR', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'TCHR',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.harvestSpeed', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'harvestSpeed',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.availableTime', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'availableTime',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.plantationAvailableTime', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'plantationAvailableTime',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.productiveTime', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'productiveTime',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.improductiveTime', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'improductiveTime',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.totalTime', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'totalStatesTime',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.statesAverageSpeed', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'statesAverageSpeed',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.workingStateAverageSpeed', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'workingStateAverageSpeed',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.maxSpeed', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'maxSpeed',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.distance', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'distance',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.averageRPM', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'averageRPM',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.tonsPerDay', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'tonsPerDay',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.tonsPerHour', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'tonsPerHour',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.anomalies', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'anomalies',
        width: 200
      }
    ],
    workFronts: [
      {
        text: i18n.t('machineSummaryReport.HEADERS.workFront', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'workFrontName',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.driveUnit', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'driveUnitName',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.TCHP', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'TCHP',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.TCHR', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'TCHR',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.harvestSpeed', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'harvestSpeed',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.availableTime', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'availableTime',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.plantationAvailableTime', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'plantationAvailableTime',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.productiveTime', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'productiveTime',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.improductiveTime', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'improductiveTime',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.totalTime', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'totalStatesTime',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.statesAverageSpeed', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'statesAverageSpeed',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.workingStateAverageSpeed', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'workingStateAverageSpeed',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.maxSpeed', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'maxSpeed',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.distance', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'distance',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.averageRPM', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'averageRPM',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.tonsPerDay', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'tonsPerDay',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.tonsPerHour', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.number,
        value: 'tonsPerHour',
        width: 200
      },
      {
        text: i18n.t('machineSummaryReport.HEADERS.anomalies', { lang }),
        align: 'left',
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: 'anomalies',
        width: 200
      }
    ]
  }
}

const detailHeaders = (headersOptions, lang) => {
  return [
    {
      text: i18n.t('machineSummaryReport.HEADERS.workFront', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'workFrontName',
      width: 200
    },
    {
      text: i18n.t('machineSummaryReport.HEADERS.machine', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'thingName',
      width: 200
    },
    {
      text: i18n.t('machineSummaryReport.HEADERS.activity', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'currentActivityName',
      width: 200
    },
    {
      text: i18n.t('machineSummaryReport.HEADERS.trackTimestamp', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      cellType: CellType.textBackgroundColor,
      value: 'trackTimestamp',
      cellConfig: {
        round: false,
        outlined: true,
        color: 'posTimestampColor'
      },
      width: 200
    },
    {
      text: i18n.t('machineSummaryReport.HEADERS.timestamp', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      cellType: CellType.textBackgroundColor,
      value: 'timestamp',
      cellConfig: {
        round: false,
        outlined: true,
        color: 'comTimestampColor'
      },
      width: 200
    },
    {
      text: i18n.t('machineSummaryReport.HEADERS.stateName', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'stateName',
      width: 200
    },
    {
      text: i18n.t('machineSummaryReport.HEADERS.speed', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'speed',
      width: 200
    },
    {
      text: i18n.t('machineSummaryReport.HEADERS.harvestSpeed', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'harvestSpeed',
      width: 200
    },
    {
      text: i18n.t('machineSummaryReport.HEADERS.rpm', { lang }),
      align: 'right',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'rpm',
      width: 100
    },
    // PROTECTOR MOTOR VIGIA
    {
      text: i18n.t('mapReport.HEADERS.PM_RPM', { lang }),
      align: 'right',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'engineRPM',
      width: 150
    },
    {
      text: i18n.t('mapReport.HEADERS.PM_OIL_SENSOR', { lang }),
      align: 'left',
      filterType: FilterType.multiSelect,
      options: headersOptions.boolean,
      selected: true,
      sortable: true,
      type: DataType.boolean,
      value: 'oilSensor',
      width: 200
    },
    {
      text: i18n.t('mapReport.HEADERS.PM_ACTUATOR', { lang }),
      align: 'left',
      filterType: FilterType.multiSelect,
      options: headersOptions.boolean,
      selected: true,
      sortable: true,
      type: DataType.boolean,
      value: 'actuator',
      width: 200
    },
    {
      text: i18n.t('mapReport.HEADERS.PM_BATTERY_TENSION', { lang }),
      align: 'right',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'batteryTension',
      width: 200
    },
    {
      text: i18n.t('mapReport.HEADERS.PM_TEMPERATURE', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'temperature',
      width: 200
    },
    // -------------------------------------------------------------------
    {
      text: i18n.t('machineSummaryReport.HEADERS.anomalies', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'anomalies',
      width: 200
    },
    {
      text: i18n.t('machineSummaryReport.HEADERS.driver', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'driverId',
      width: 200
    },
    {
      text: i18n.t('machineSummaryReport.HEADERS.driveUnit', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'driveUnitName',
      width: 200
    },
    {
      text: i18n.t('machineSummaryReport.HEADERS.farm', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'farm'
    },
    {
      text: i18n.t('machineSummaryReport.HEADERS.ecologicalUnit', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'ecologicalUnit',
      width: 100
    },
    {
      text: i18n.t('machineSummaryReport.HEADERS.variety', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'variety',
      width: 150
    },
    {
      text: i18n.t('machineSummaryReport.HEADERS.age', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'age'
    },
    {
      text: i18n.t('currentStatusReport.HEADERS.netArea', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'area',
      width: 100
    },
    {
      text: i18n.t('currentStatusReport.HEADERS.TCHP', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'tchp'
    },
    {
      text: i18n.t('currentStatusReport.HEADERS.netGrooves', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'grooves',
      width: 100
    },
    {
      text: i18n.t('currentStatusReport.HEADERS.TCH', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'tch'
    },
    {
      text: i18n.t('currentStatusReport.HEADERS.TCHR', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'tchr'
    },
    {
      text: i18n.t('currentStatusReport.HEADERS.kgGroove', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'kgPerGroove',
      width: 100
    },
    {
      text: i18n.t('currentStatusReport.HEADERS.hectareGroove', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'groovesPerHectare',
      width: 100
    },
    {
      text: i18n.t('currentStatusReport.HEADERS.machineEfficiency', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'machineEfficiency',
      width: 100
    },
    {
      text: i18n.t('currentStatusReport.HEADERS.distanceMill', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'distanceToMill',
      width: 100
    },
    {
      text: i18n.t('currentStatusReport.HEADERS.harvestWidth', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'headWidth',
      width: 100
    },
    {
      text: i18n.t('currentStatusReport.HEADERS.plantingWidth', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'plantingWidth',
      width: 100
    },
    {
      text: i18n.t('currentStatusReport.HEADERS.nivelationWidth', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'nivelationWidth',
      width: 100
    }
  ]
}

/**
 * Es el mismo mapa que el detalle del reporte de área trabajada
 * @param lang
 * @param i18n
 */
const detailSpeedMap = (lang) => {
  return {
    positionColumn: 'position',
    dataColumn: 'speed',
    ownerColumn: 'thingName',
    serieType: SerieType.LINE,
    mapName: i18n.t('workedAreaReport.Detail.Maps.SpeedTitle', { lang }),
    references: [
      {
        name: 'lento',
        color: '#FFF933',
        isInterval: true,
        intervalStart: 0,
        intervalEnd: 4
      },
      {
        name: 'normal',
        color: '#80FF33',
        isInterval: true,
        intervalStart: 4,
        intervalEnd: 8
      },
      {
        name: 'rapido',
        color: '#F90611',
        isInterval: true,
        intervalStart: 8,
        intervalEnd: 100
      }
    ],
    showMarkers: false,
    groupName: 'speed',
    groupText: i18n.t('workedAreaReport.Detail.Maps.SpeedGroupTitle', { lang }),
    filters: [
      {
        label: i18n.t('workedAreaReport.Detail.Headers.State', { lang }),
        values: [],
        position: 0,
        property: 'stateName'
      }
    ]
  }
}

/**
 * Es el mismo mapa que el detalle del reporte de área trabajada
 * @param lang
 * @param i18n
 */
const detailStateMap = (lang, references) => {
  return {
    positionColumn: 'position',
    dataColumn: 'stateName',
    ownerColumn: 'thingName',
    serieType: SerieType.LINE,
    mapName: i18n.t('workedAreaReport.Detail.Maps.StateTitle', { lang }),
    references,
    showMarkers: false,
    groupName: 'state',
    groupText: i18n.t('workedAreaReport.Detail.Maps.StateGroupTitle', { lang }),
    filters: []
  }
}

export const reportToExportConstants ={
  sectors:{
    FIELD_ENGINEERING: "FIELD_ENGINEERING",
    FARM: "FARM",
    TRANSPORT: "TRANSPORT",
    HARVEST: "HARVEST"
  },
  farmStates:[
    "PARADA",
    "IDLE",
    "MANEUVERING",
    "WORKING",
    "TRANSFER",
    "PLANTING",
    "PLANTING_FUNGICIDE",
    "PLANTING_FERTILIZER",
    "PLANTING_FUNGICIDE_FERTILIZER",
    "MARKING"
  ],
  farmActivities:[
    "PLANTATION",
    "HARVEST_PLANT",
    "CANE_TRANSPORT",
    "ATTACHMENT_MOVEMENT"
  ],
  //IMPORTATE: RESPETAR EL ORDEN (para que quede compatible con Power Bi)
  farmHeaders:[
    {value:'workFrontName', text: 'Frente de trabajo'},
    {value:'thingName', text: 'Equipo'},
    {value:'activityName', text:'actividad'},
    {value:'availableTime', text:'Tiempo Disponible'},
    {value:'plantationAvailableTime', text:'Tiempo disponible de plantación'},
    {value:'productiveTime', text:'Tiempo productivo'},
    {value:'improductiveTime', text:'Tiempo improductivo'},
    {value:'totalStatesTime', text:'Tiempo Total'},
    {value:'statesAverageSpeed', text:'Velocidad Promedio (Km/h)'},
    {value:'workingStateAverageSpeed', text:'Velocidad Promedio de Trabajo (Km/h)'},
    {value:'maxSpeed', text:'Velocidad Máxima (Km/h)'},
    {value:'distance', text:'Distancia Recorrida (Km)'},
    {value:'averageRPM', text:'RPM promedio'},
    {value: "PARADA" , text: "Estado: PARADA"},
    {value: "IDLE" ,text: "Estado: MOTOR OCIOSO"},
    {value: "MANEUVERING" ,text: "Estado: MANIOBRANDO"},
    {value: "WORKING",text: "Estado: TRABAJANDO"},
    {value: "TRANSFER",text: "Estado: TRASLADO"},
    {value: "PLANTING",text: "Estado: PLANTANDO"},
    {value: "PLANTING_FUNGICIDE",text: "Estado: PLANTANDO CON FUNGUICIDA"},
    {value: "PLANTING_FERTILIZER",text: "Estado: PLANTANDO CON ABONO"},
    {value: "PLANTING_FUNGICIDE_FERTILIZER",text: "Estado: PLANTANDO CON FUNGUICIDA Y ABONO"},
    {value: "MARKING",text: "Estado: MARCANDO"},
    {value: "PLANTATION",text: "Área Trabajada: PLANTACION"},
    {value: "HARVEST_PLANT",text: "Área Trabajada: COSECHA PARA PLANTACION"},
    {value: "CANE_TRANSPORT",text: "Área Trabajada: TRANSPORTE DE CAÑA"},
    {value: "ATTACHMENT_MOVEMENT",text: "Área Trabajada: MOVIMIENTO DE IMPLEMENTOS"},

    {value: "DISTANCE_PARADA" , text: "Distancia: PARADA"},
    {value: "DISTANCE_IDLE" ,text: "Distancia: MOTOR OCIOSO"},
    {value: "DISTANCE_MANEUVERING" ,text: "Distancia: MANIOBRANDO"},
    {value: "DISTANCE_WORKING",text: "Distancia: TRABAJANDO"},
    {value: "DISTANCE_TRANSFER",text: "Distancia: TRASLADO"},
    {value: "DISTANCE_PLANTING",text: "Distancia: PLANTANDO"},
    {value: "DISTANCE_PLANTING_FUNGICIDE",text: "Distancia: PLANTANDO CON FUNGUICIDA"},
    {value: "DISTANCE_PLANTING_FERTILIZER",text: "Distancia: PLANTANDO CON ABONO"},
    {value: "DISTANCE_PLANTING_FUNGICIDE_FERTILIZER",text: "Distancia: PLANTANDO CON FUNGUICIDA Y ABONO"},
    {value: "DISTANCE_MARKING",text: "Distancia: MARCANDO"},

    {value: "AREA_PARADA" , text: "Área (ha.): PARADA"},
    {value: "AREA_IDLE" ,text: "Área (ha.): MOTOR OCIOSO"},
    {value: "AREA_MANEUVERING" ,text: "Área (ha.): MANIOBRANDO"},
    {value: "AREA_WORKING",text: "Área (ha.): TRABAJANDO"},
    {value: "AREA_TRANSFER",text: "Área (ha.): TRASLADO"},
    {value: "AREA_PLANTING",text: "Área (ha.): PLANTANDO"},
    {value: "AREA_PLANTING_FUNGICIDE",text: "Área (ha.): PLANTANDO CON FUNGUICIDA"},
    {value: "AREA_PLANTING_FERTILIZER",text: "Área (ha.): PLANTANDO CON ABONO"},
    {value: "AREA_PLANTING_FUNGICIDE_FERTILIZER",text: "Área (ha.): PLANTANDO CON FUNGUICIDA Y ABONO"},
    {value: "AREA_MARKING",text: "Área (ha.): MARCANDO"},

  ],
  engineeringActivities: [
    "SOIL_PREPARATION",
    "SOIL_MOVEMENT"
  ],
   //IMPORTATE: RESPETAR EL ORDEN (para que quede compatible con power Bi)
  engineeringHeaders: [
    {value:'thingName', text: 'Equipo'},
    {value:'activityName', text:'Actividad'},
    {value:'availableTime', text:'Tiempo Disponible'},
    {value:'productiveTime', text:'Tiempo productivo'},
    {value:'improductiveTime', text:'Tiempo improductivo'},
    {value:'totalStatesTime', text:'Tiempo Total'},
    {value:'statesAverageSpeed', text:'Velocidad Promedio (Km/h)'},
    {value:'maxSpeed', text:'Velocidad Máxima (Km/h)'},
    {value:'workingStateAverageSpeed', text:'Velocidad Promedio de Trabajo (Km/h)'},
    {value:'distance', text:'Distancia Recorrida (Km)'},
    {value: "WORKING",text: "Estado: TRABAJANDO", },
    {value: "MANEUVERING" ,text: "Estado: MANIOBRANDO", },
    {value: "MOVING",text: "Estado: MOVIMIENTO", },
    {value: "TRANSFER",text: "Estado: TRASLADO", },
    {value: "IDLE" ,text: "Estado: MOTOR OCIOSO", },
    {value:  "PARADA" , text: "Estado: PARADA", },
    {value: "ADVERTENCIA_6091384736b4af001a346977",text: "Estado: ADVERTENCIA", },
    {value:'SOIL_PREPARATION', text:'Área Trabajada: PREPARACIÓN DE SUELO'},
    {value: 'SOIL_MOVEMENT', text:'Área Trabajada: MOVIMIENTO DE SUELOS'},

    {value: "DISTANCE_WORKING",text: "Distancia: TRABAJANDO", },
    {value: "DISTANCE_MANEUVERING" ,text: "Distancia: MANIOBRANDO", },
    {value: "DISTANCE_MOVING",text: "Distancia: MOVIMIENTO", },
    {value: "DISTANCE_TRANSFER",text: "Distancia: TRASLADO", },
    {value: "DISTANCE_IDLE" ,text: "Distancia: MOTOR OCIOSO", },
    {value: "DISTANCE_PARADA" , text: "Distancia: PARADA", },
    {value: "DISTANCE_ADVERTENCIA_6091384736b4af001a346977" , text: "Distancia: ADVERTENCIA", },

    {value: "AREA_WORKING",text: "Área (ha.): TRABAJANDO", },
    {value: "AREA_MANEUVERING" ,text: "Área (ha.): MANIOBRANDO", },
    {value: "AREA_MOVING",text: "Área (ha.): MOVIMIENTO", },
    {value: "AREA_TRANSFER",text: "Área (ha.): TRASLADO", },
    {value: "AREA_IDLE" ,text: "Área (ha.): MOTOR OCIOSO", },
    {value: "AREA_PARADA" , text: "Área (ha.): PARADA", },
    {value: "AREA_ADVERTENCIA_6091384736b4af001a346977" , text: "Área (ha.): ADVERTENCIA", },
  ],
  engineeringStates:[
    "WORKING",
     "MANEUVERING" ,
     "MOVING",
     "TRANSFER",
     "IDLE",
     "PARADA",
    "ADVERTENCIA_6091384736b4af001a346977"
  ],
  transportStates:[
    "PROXIMO_INGENIO_1",
    "PROXIMO_INGENIO_2",
    "CONTRACTOR_WORKSHOP_IN",
    "CONTRACTOR_WORKSHOP_OUT",
    "HARVEST_FRONT_IN",
    "HARVEST_FRONT_OUT",
    "SUGARMILL_IN",
    "TRAPICHE_IN",
    "ESPERA_VACIO",
    "TRAVELING",
    "FUERA_SERVICIO",
    "OWN_WORKSHOP_IN",
    "OWN_WORKSHOP_OUT",
  ],
  //IMPORTATE: RESPETAR EL ORDEN (para que quede compatible con power Bi)
  transportHeaders: [
    {value:'workFrontName', text: 'Frente de trabajo'},
    {value:'thingName', text: 'Equipo'},
    {value:'activityName', text:'Actividad'},
    {value:'availableTime', text:'Tiempo Disponible'},
    {value:'plantationAvailableTime', text:'Tiempo disponible de plantación'},
    {value:'productiveTime', text:'Tiempo productivo'},
    {value:'improductiveTime', text:'Tiempo improductivo'},
    {value:'totalStatesTime', text:'Tiempo Total'},
    {value:'statesAverageSpeed', text:'Velocidad Promedio (Km/h)'},
    {value:'workingStateAverageSpeed', text:'Velocidad Promedio de Trabajo (Km/h)'},
    {value:'maxSpeed', text:'Velocidad Máxima (Km/h)'},
    {value:'distance', text:'Distancia Recorrida (Km)'},
    {value:'averageRPM', text:'RPM promedio'},

    //estos 4 no están llegando hasta el momento, verificar cuando se comiencen a traer.
    {value:'tonsPerDay', text:'Toneladas por día'},
    {value:'tonsPerHour', text:'Toneladas por hora'},
    {value:'TCHP', text:'TCHP'},
    {value:'anomalies', text:'Anomalías'},

    {value: "PROXIMO_INGENIO_1" , text: "Estado: Próximo a Ingenio 1"},
    {value: "PROXIMO_INGENIO_2" , text: "Estado: Próximo a Ingenio 2"},
    {value: "CONTRACTOR_WORKSHOP_IN" , text: "Estado: TALLER CONTRATISTA"},
    {value: "CONTRACTOR_WORKSHOP_OUT" , text: "Estado: EGRESO TALLER CONTRATISTA"},
    {value: "HARVEST_FRONT_IN" , text: "Estado: INGRESO FRENTE DE COSECHA"},
    {value: "HARVEST_FRONT_OUT" , text: "Estado: EGRESO FRENTE DE COSECHA"},
    {value: "SUGARMILL_IN" , text: "Estado: INGRESO A INGENIO"},
    {value: "TRAPICHE_IN" , text: "Estado: TRAPICHE"},
    {value: "ESPERA_VACIO" , text: "Estado: ESPERA EN VACIO"},
    {value: "TRAVELING" , text: "Estado: EN VIAJE"},
    {value: "FUERA_SERVICIO" , text: "Estado: FUERA DE SERVICIO"},
    {value: "OWN_WORKSHOP_IN" , text: "Estado: TALLER PROPIO"},
    {value: "OWN_WORKSHOP_OUT" , text: "Estado: EGRESO TALLER PROPIO"},

    {value: "DISTANCE_PROXIMO_INGENIO_1" , text: "Distancia: Próximo a Ingenio 1"},
    {value: "DISTANCE_PROXIMO_INGENIO_2" , text: "Distancia: Próximo a Ingenio 2"},
    {value: "DISTANCE_CONTRACTOR_WORKSHOP_IN" , text: "Distancia: TALLER CONTRATISTA"},
    {value: "DISTANCE_CONTRACTOR_WORKSHOP_OUT" , text: "Distancia: EGRESO TALLER CONTRATISTA"},
    {value: "DISTANCE_HARVEST_FRONT_IN" , text: "Distancia: INGRESO FRENTE DE COSECHA"},
    {value: "DISTANCE_HARVEST_FRONT_OUT" , text: "Distancia: EGRESO FRENTE DE COSECHA"},
    {value: "DISTANCE_SUGARMILL_IN" , text: "Distancia: INGRESO A INGENIO"},
    {value: "DISTANCE_TRAPICHE_IN" , text: "Distancia: TRAPICHE"},
    {value: "DISTANCE_ESPERA_VACIO" , text: "Distancia: ESPERA EN VACIO"},
    {value: "DISTANCE_TRAVELING" , text: "Distancia: EN VIAJE"},
    {value: "DISTANCE_FUERA_SERVICIO" , text: "Distancia: FUERA DE SERVICIO"},
    {value: "DISTANCE_OWN_WORKSHOP_IN" , text: "Distancia: TALLER PROPIO"},
    {value: "DISTANCE_OWN_WORKSHOP_OUT" , text: "Distancia: EGRESO TALLER PROPIO"},


    {value: "AREA_PROXIMO_INGENIO_1" , text: "Área (ha.): Próximo a Ingenio 1"},
    {value: "AREA_PROXIMO_INGENIO_2" , text: "Área (ha.): Próximo a Ingenio 2"},
    {value: "AREA_CONTRACTOR_WORKSHOP_IN" , text: "Área (ha.): TALLER CONTRATISTA"},
    {value: "AREA_CONTRACTOR_WORKSHOP_OUT" , text: "Área (ha.): EGRESO TALLER CONTRATISTA"},
    {value: "AREA_HARVEST_FRONT_IN" , text: "Área (ha.): INGRESO FRENTE DE COSECHA"},
    {value: "AREA_HARVEST_FRONT_OUT" , text: "Área (ha.): EGRESO FRENTE DE COSECHA"},
    {value: "AREA_SUGARMILL_IN" , text: "Área (ha.): INGRESO A INGENIO"},
    {value: "AREA_TRAPICHE_IN" , text: "Área (ha.): TRAPICHE"},
    {value: "AREA_ESPERA_VACIO" , text: "Área (ha.): ESPERA EN VACIO"},
    {value: "AREA_TRAVELING" , text: "Área (ha.): EN VIAJE"},
    {value: "AREA_FUERA_SERVICIO" , text: "Área (ha.): FUERA DE SERVICIO"},
    {value: "AREA_OWN_WORKSHOP_IN" , text: "Área (ha.): TALLER PROPIO"},
    {value: "AREA_OWN_WORKSHOP_OUT" , text: "Área (ha.): EGRESO TALLER PROPIO"},

  ],
  harvestStates:[
    "WORKING",
    "MANEUVERING",
    "TRANSFER",
    "IDLE",
    "PARADA",
    "FALTA_VACIO"
  ],
  harvestActivities:[
    "SOIL_PREPARATION",
    "IRRIGATION",
    "HARVEST"
  ],
  harvestHeaders:[
    {value:'workFrontName', text: 'Frente de trabajo'},
    {value:'thingName', text: 'Equipo'},
    {value:'activityName', text:'Actividad'},
    {value:'availableTime', text:'Tiempo Disponible'},
    {value:'productiveTime', text:'Tiempo productivo'},
    {value:'improductiveTime', text:'Tiempo improductivo'},
    {value:'totalStatesTime', text:'Tiempo Total'},
    {value:'statesAverageSpeed', text:'Velocidad Promedio (Km/h)'},
    {value:'workingStateAverageSpeed', text:'Velocidad Promedio de Trabajo (Km/h)'},
    {value:'maxSpeed', text:'Velocidad Máxima (Km/h)'},
    {value:'distance', text:'Distancia Recorrida (Km)'},
    {value:'averageRPM', text:'RPM promedio'},

    {value: "WORKING",text: "Estado: TRABAJANDO"},
    {value: "MANEUVERING" ,text: "Estado: MANIOBRANDO"},
    {value: "TRANSFER",text: "Estado: TRASLADO"},
    {value: "IDLE" ,text: "Estado: MOTOR OCIOSO"},
    {value: "PARADA" , text: "Estado: PARADA"},
    {value: "FALTA_VACIO",text: "Estado: FALTA DE VACÍO"},
    {value: "HARVEST", text: "Área Trabajada: COSECHA"},
    {value: "SOIL_PREPARATION", text: "Área Trabajada: PREPARACIÓN DE SUELO"},
    {value: "IRRIGATION", text: "Área Trabajada: RIEGO"},
    {value:'driveUnitName', text:'Unidad de manejo'},

    {value: "DISTANCE_WORKING",text: "Distancia: TRABAJANDO"},
    {value: "DISTANCE_MANEUVERING" ,text: "Distancia: MANIOBRANDO"},
    {value: "DISTANCE_TRANSFER",text: "Distancia: TRASLADO"},
    {value: "DISTANCE_IDLE" ,text: "Distancia: MOTOR OCIOSO"},
    {value: "DISTANCE_PARADA" , text: "Distancia: PARADA"},
    {value: "DISTANCE_FALTA_VACIO",text: "Distancia: FALTA DE VACÍO"},

    {value: "AREA_WORKING",text: "Área (ha.): TRABAJANDO"},
    {value: "AREA_MANEUVERING" ,text: "Área (ha.): MANIOBRANDO"},
    {value: "AREA_TRANSFER",text: "Área (ha.): TRASLADO"},
    {value: "AREA_IDLE" ,text: "Área (ha.): MOTOR OCIOSO"},
    {value: "AREA_PARADA" , text: "Área (ha.): PARADA"},
    {value: "AREA_FALTA_VACIO",text: "Área (ha.): FALTA DE VACÍO"},

  ]

}

export default {
  detailStateMap,
  summaryHeadersThings,
  summaryHeadersDriveUnits,
  detailHeaders,
  detailSpeedMap,

}
