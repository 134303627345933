import service from '@/middleware'

const machineSummaryReportApi = {
  async getSummaryReportByDriveUnits (driveUnits, types, activities, from, to, route, filters) {
    const body = {
      reportQueryFilters: {
        types,
        driveUnits,
        activities,
        from,
        to,
        route
      },
      filters
    }
    const result = await service.http.post('/api/machine-summary/deferred', body)
    return result
  },
  async getSummaryReportByDriveUnitsDirect (driveUnits, sectorKey, types, activities, from, to, timeFormat) {
    const body = {
      driveUnits,
      from,
      to,
      activities,
      types,
      sectorKey,
      timeFormat
    }
    const result = await service.http.post('/api/machine-summary/drive-units/not-deferred', body)
    return result
  },
  async getSummaryReportByThings (thingsByType, sectorKeys, activities, from, to, route, filters) {
    const body = {
      reportQueryFilters: {
        sectorKeys,
        thingsByType,
        activities,
        from,
        to,
        route
      },
      filters
    }
    const result = await service.http.post('/api/machine-summary/deferred', body)
    return result
  },
  async getSummaryReportByThingsDirect (from, to, thingsByType, sectorKeys, activities, timeFormat) {
    const body = {
      thingsByType,
      sectorKeys,
      from,
      to,
      activities,
      timeFormat
    }
    const result = await service.http.post('/api/machine-summary/things/not-deferred', body)
    return result
  },
  async getDetailReport (from, to, thingId, typeKey, activities = [], driveUnits) {
    const result = await service.http.post('/api/machine-summary/detail', {
      from,
      to,
      typeKey,
      thingId,
      activities,
      driveUnits
    })
    return result
  },
  async getStatesTypeByKey (stateTypeKeys) {
    const result = await service.http.post('/api/machine-summary/types-by-keys', { stateTypeKeys })
    return result.data ? result.data : []
  }
}

export default machineSummaryReportApi
