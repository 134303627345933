/* eslint-disable indent */
import machineSummaryReportApi from '@/api/machineSummaryReport.api'
import enterpriseApi from '@/api/enterprise.api'
import stateApi from '@/api/state.api'
import autoReportService from './autoReportService'
import i18n from '@/i18n'
import MachineSummaryReportConstants from '../constants/machineSummaryReport.constants'
import { reportToExportConstants } from '../constants/machineSummaryReport.constants'
import conversion from '../utils/conversion.util'
import { getTimeOffset } from '@/tools/functions'
import {
  Axes,
  Chart,
  ChartType,
  Dataset,
  DataType,
  FilterType,
  Options,
  ReportConversionUtil,
  Util,
  ValueText
} from '@colven/common-domain-lib/lib'
import conversionUtil from '../utils/conversion.util'
import reportsUtil from '../utils/reports.util'
import { clone } from 'ramda'

// respuesta por defecto cuando se hace una llamada de api y falla
const DEFAULT_RESPONSE = {
  things: [],
  workFronts: [],
  drivers: [],
  thingHeaders: [],
  workFrontHeaders: [],
  driverHeaders: [],
  timeByStateCharts: {
    thingCharts: [],
    things: [],
    workFrontCharts: [],
    workFronts: [],
    driverCharts: [],
    drivers: []
  },
  availableTimeCharts: {
    includePlantationAvailableTime: false,
    thingCharts: [],
    things: [],
    workFrontCharts: [],
    workFronts: [],
    driverCharts: [],
    drivers: [],
    plantationThingCharts: [],
    plantationThings: [],
    plantationDriverCharts: [],
    plantationDrivers: [],
    plantationWorkFrontCharts: [],
    plantationWorkFronts: []
  }
};

/**
 * Reporte resumen generado por cosas (diferido)
 * @param {*} things
 * @param {*} sectorKey
 * @param {*} types
 * @param {*} activities
 * @param {*} timestampFrom
 * @param {*} timestampTo
 * @param {*} sinceDate
 * @param {*} sinceTime
 * @param {*} toDate
 * @param {*} toTime
 * @param {*} dateTimeRange
 * @param {*} customDateTimeRangeType
 * @param {*} route
 * @param {*} timeFormat
 * @param {*} filterSwitch
 */


const getReportByThingsDeferred = async (
  things, sectorKey, types, activities, timestampFrom, timestampTo, sinceDate, sinceTime, toDate, toTime,
  dateTimeRange, customDateTimeRangeType, route, timeFormat, filterSwitch) => {
  const title = i18n.t("machineSummaryReport.title");
  const filters = {
    things,
    sectorKey,
    types,
    activities,
    timestampFrom,
    timestampTo,
    sinceDate,
    sinceTime,
    toDate,
    toTime,
    dateTimeRange,
    customDateTimeRangeType,
    route,
    timeFormat,
    filterSwitch,
    title
  };
  try {
    const { thingsByType, sectorKeys } = getThingsByTypeAndSectorKeys(things);
    await machineSummaryReportApi.getSummaryReportByThings(thingsByType, sectorKeys, activities.map(a => a.id), timestampFrom, timestampTo, route, filters);
  } catch (e) {
    console.error(e);
  }
  return null;
}

/**
 * Reporte resumen generado por unidades de manejo (diferido)
 * @param {*} driveUnits
 * @param {*} sectorKey
 * @param {*} types
 * @param {*} activities
 * @param {*} timestampFrom
 * @param {*} timestampTo
 * @param {*} sinceDate
 * @param {*} sinceTime
 * @param {*} toDate
 * @param {*} toTime
 * @param {*} dateTimeRange
 * @param {*} customDateTimeRangeType
 * @param {*} route
 * @param {*} timeFormat
 * @param {*} filterSwitch
 */
const getReportByDriveUnitsDeferred = async (
  driveUnits, sectorKey, types, activities, timestampFrom, timestampTo, sinceDate, sinceTime, toDate, toTime,
  dateTimeRange, customDateTimeRangeType, route, timeFormat, filterSwitch) => {
  const title = i18n.t("machineSummaryReport.title");
  const filters = {
    driveUnits,
    sectorKey,
    types,
    activities,
    timestampFrom,
    timestampTo,
    sinceDate,
    sinceTime,
    toDate,
    toTime,
    dateTimeRange,
    customDateTimeRangeType,
    route,
    timeFormat,
    filterSwitch,
    title
  };
  try {
    await machineSummaryReportApi.getSummaryReportByDriveUnits(driveUnits.map(d => d.id), types.map(t => t.key), activities.map(a => a.id), timestampFrom, timestampTo, route, filters);
  } catch (e) {
    console.error(e);
  }
  return null;
}

/**
 * Reporte resumen generado por cosas (directo)
 * @param {*} timestampFrom
 * @param {*} timestampTo
 * @param {*} things
 * @param {*} activities
 * @param {*} timeFormat
 * @returns
 */
const getReportByThings = async (things, activities, timestampFrom, timestampTo, timeFormat) => {
  try {
    const { thingsByType, sectorKeys } = getThingsByTypeAndSectorKeys(things);
    const response = await machineSummaryReportApi.getSummaryReportByThingsDirect(
      timestampFrom, timestampTo, thingsByType, sectorKeys, activities, timeFormat);
    return response.data;
  } catch (e) {
    console.error(e);
    return DEFAULT_RESPONSE;
  }
}

/**
 * Reporte resumen generado por unidades de manejo (directo)
 * @param {*} driveUnits
 * @param {*} sectorKey
 * @param {*} activities
 * @param {*} types
 * @param {*} timestampFrom
 * @param {*} timestampTo
 * @param {*} timeFormat
 * @returns
 */
const getReportByDriveUnits = async (driveUnits, sectorKey, activities, types, timestampFrom, timestampTo, timeFormat) => {
  try {
    const response = await machineSummaryReportApi.getSummaryReportByDriveUnitsDirect(
      driveUnits, sectorKey, types, activities, timestampFrom, timestampTo, timeFormat);
    return response.data;
  } catch (e) {
    console.error(e);
    return DEFAULT_RESPONSE;
  }
}

/**
 * AUTO-REPORTE
 * Reporte resumen generado por cosas
 * @param {*} things
 * @param {*} sectors
 * @param {*} types
 * @param {*} activities
 * @param {*} timestampFrom
 * @param {*} timestampTo
 * @param {*} sinceDate
 * @param {*} sinceTime
 * @param {*} toDate
 * @param {*} toTime
 * @param dateTimeRange
 * @param customDateTimeRangeType
 * @param {*} timeFormat
 * @param {*} filterSwitch
 * @param {*} route
 * @param autoReportMinutes
 * @param autoReportName
 * @param {*} reportId
 * @param currentQueryParams
 * @returns
 */
const getAutoReportByThings = async (things, sectors, types, activities, timestampFrom, timestampTo, sinceDate, sinceTime, toDate, toTime,
  dateTimeRange, customDateTimeRangeType, timeFormat, filterSwitch, route, autoReportMinutes, autoReportName, reportId = null, currentQueryParams = null) => {
  try {
    const { thingsByType, sectorKeys } = getThingsByTypeAndSectorKeys(things);
    const response = await machineSummaryReportApi.getSummaryReportByThingsDirect(
      timestampFrom, timestampTo, thingsByType, sectorKeys, activities, timeFormat);
    const filters = {
      things,
      sectors,
      types,
      activities,
      from: timestampFrom,
      to: timestampTo,
      sinceDate,
      sinceTime,
      toDate,
      toTime,
      dateTimeRange,
      customDateTimeRangeType,
      timeFormat,
      filterSwitch,
      autoReport: true,
      autoReportMinutes,
      autoReportName
    };
    const id = await autoReportService.saveAutoReport(response.data, filters, route, i18n.t('machines.reports.summary'), autoReportName, reportId);

    // cambiar query params de ser necesario
    autoReportService.updateQueryParams(route, currentQueryParams, id);

    return { id, data: response.data };
  } catch (e) {
    console.error(e);
    return { id: null, data: DEFAULT_RESPONSE };
  }
}

/**
 * AUTO-REPORTE
 * Reporte resumen generado por unidades de manejo
 * @param {*} driveUnits
 * @param {*} sectors
 * @param {*} types
 * @param {*} activities
 * @param {*} timestampFrom
 * @param {*} timestampTo
 * @param {*} sinceDate
 * @param {*} sinceTime
 * @param {*} toDate
 * @param {*} toTime
 * @param dateTimeRange
 * @param customDateTimeRangeType
 * @param {*} timeFormat
 * @param {*} filterSwitch
 * @param {*} route
 * @param autoReportMinutes
 * @param autoReportName
 * @param {*} reportId
 * @param currentQueryParams
 * @returns
 */
const getAutoReportByDriveUnits = async (driveUnits, sectors, types, activities, timestampFrom, timestampTo, sinceDate, sinceTime, toDate, toTime,
  dateTimeRange, customDateTimeRangeType, timeFormat, filterSwitch, route, autoReportMinutes, autoReportName, reportId = null, currentQueryParams = null) => {
  try {
    // clave del sector
    const sectorKey = (sectors.length === 1 && sectors[0].key) || null;
    const response = await machineSummaryReportApi.getSummaryReportByDriveUnitsDirect(
      driveUnits, sectorKey, types, activities, timestampFrom, timestampTo, timeFormat);
    const filters = {
      driveUnits,
      sectors,
      types,
      activities,
      from: timestampFrom,
      to: timestampTo,
      sinceDate,
      sinceTime,
      toDate,
      toTime,
      dateTimeRange,
      customDateTimeRangeType,
      timeFormat,
      filterSwitch,
      autoReport: true,
      autoReportMinutes,
      autoReportName
    };
    const id = await autoReportService.saveAutoReport(response.data, filters, route, i18n.t('machines.reports.summary'), autoReportName, reportId);

    // cambiar query params de ser necesario
    autoReportService.updateQueryParams(route, currentQueryParams, id);

    return { id, data: response.data };
  } catch (e) {
    console.error(e);
    return { id: null, data: DEFAULT_RESPONSE };
  }
}

/**
 * Obtener el arreglo de cosas agrupadas por tipo de máquina
 * @param {*} things
 * @returns
 */
const getThingsByTypeAndSectorKeys = (things) => {
  const thingsByType = [];
  const typeKeys = [...new Set(things.map(t => t.typeKey))];
  const sectorKeys = [...new Set(things.map(t => t.sectorKey))];
  typeKeys.forEach(key => {
    thingsByType.push({
      typeKey: key,
      things: things.filter(t => t.typeKey === key).map(t => ({ id: t.id, sectorKey: t.sectorKey }))
    });
  })
  return { thingsByType, sectorKeys };
}

const processSummaryData = async (data, from, to, timeFormat) => {
  const timeOffset = getTimeOffset();
  const lang = localStorage.getItem('locale');
  let response = await getSummaryReport(data, from, to, lang, timeOffset, timeFormat.value, true);
  return response
}

const processSummaryDataDetail = async (data) => {
  const timeOffset = getTimeOffset();
  const lang = localStorage.getItem('locale');
  return processDetailData(data, lang, timeOffset);
}

/**
 * Obtener reporte resumen de maquinas
 * @param reportData
 * @param from
 * @param to
 * @param lang
 * @param offset
 * @param timeFormat
 * @param withDriveUnits
 * @returns
 */
const getSummaryReport = async (reportData, from, to, lang, offset, timeFormat, withDriveUnits = false) => {
  // headers
  const headers = withDriveUnits
    ? MachineSummaryReportConstants.summaryHeadersDriveUnits(lang)
    : MachineSummaryReportConstants.summaryHeadersThings(lang);

  // procesamiento de datos
  const thingsData = processSummaryTableData(reportData.things, 'thingId', lang, timeFormat);
  const driversData = processSummaryTableData(reportData.drivers, 'driverCode', lang, timeFormat);
  const workFrontsData = processSummaryTableData(reportData.workFronts, 'workFrontId', lang, timeFormat);

  // gráficos
  const charts = await getcharts(reportData, from, to, lang, offset, timeFormat);

  return {
    things: thingsData.data,
    thingHeaders: [...headers.things, ...thingsData.additionalHeaders],
    drivers: driversData.data,
    driverHeaders: [...headers.drivers, ...driversData.additionalHeaders],
    workFronts: workFrontsData.data,
    workFrontHeaders: [...headers.workFronts, ...workFrontsData.additionalHeaders],
    ...charts
  };
}

/**
 * Procesa los datos para la tabla del resumen
 * @param data
 * @param idField
 * @param lang
 * @param timeFormat
 * @returns
 */
const processSummaryTableData = (data, idField, lang, timeFormat) => {
  const reportData = [];
  const additionalHeaders = [];
  let newData;
  data.forEach((machine, index) => {
    newData = {
      id: `${index}-${machine[idField]}`,
      thingId: machine.thingId ? machine.thingId : undefined,
      thingName: machine.thingName ? machine.thingName : undefined,
      typeKey: machine.typeKey,
      workFrontId: machine.workFrontId ? machine.workFrontId : undefined,
      workFrontName: machine.workFrontName ? machine.workFrontName : undefined,
      driverCode: machine.driverCode ? machine.driverCode : undefined,
      driverName: machine.driverName ? machine.driverName : undefined,
      driveUnitId: machine.driveUnitId ? machine.driveUnitId : undefined,
      driveUnitName: machine.driveUnitName ? machine.driveUnitName : undefined,
      harvestSpeed: conversion.translateDecimalToValueText(machine.harvestSpeed),
      currentActivityId: machine.currentActivityId ? machine.currentActivityId : undefined,
      currentActivityName: machine.currentActivityName ? machine.currentActivityName : undefined,
      distance: conversion.translateDecimalToValueText(machine.distance),
      averageRPM: conversion.translateDecimalToValueText(machine.averageRPM),
      totalStatesTime: machine.totalStatesTime ? new ValueText(machine.totalStatesTime, ReportConversionUtil.secondsToStringFormatted(machine.totalStatesTime, timeFormat)) : undefined,
      availableTime: machine.availableTime ? new ValueText(machine.availableTime, ReportConversionUtil.secondsToStringFormatted(machine.availableTime, timeFormat)) : undefined,
      // tiempo disponible de la actividad plantación
      plantationAvailableTime: machine.plantationAvailableTime
        ? new ValueText(machine.plantationAvailableTime, ReportConversionUtil.secondsToStringFormatted(machine.plantationAvailableTime, timeFormat))
        : undefined,
      statesAverageSpeed: conversion.translateDecimalToValueText(machine.statesAverageSpeed),
      workingStateAverageSpeed: conversion.translateDecimalToValueText(machine.workingStateAverageSpeed),
      maxSpeed: conversion.translateDecimalToValueText(machine.maxSpeed),
      // tiempo productivo e improductivo
      productiveTime: machine.productiveTime != null
        ? new ValueText(machine.productiveTime, ReportConversionUtil.secondsToStringFormatted(machine.productiveTime, timeFormat))
        : undefined,
      improductiveTime: machine.improductiveTime != null
        ? new ValueText(machine.improductiveTime, ReportConversionUtil.secondsToStringFormatted(machine.improductiveTime, timeFormat))
        : undefined,
      workedArea: machine.workedArea,
      statesTime: machine.statesTime,
      statesArea: machine.statesArea,
      statesDistance: machine.statesDistance
    };
    machine.statesTime.forEach(stateTime => {
      newData[`STATE_${stateTime.key}`] =
        new ValueText(stateTime.value, ReportConversionUtil.secondsToStringFormatted(stateTime.value, timeFormat))
      if (!additionalHeaders.find(header => header.value === `STATE_${stateTime.key}`)) {
        additionalHeaders.push(getAdditionalHeader(
          i18n.t('machineSummaryReport.HEADERS.state', { lang }) + stateTime.name[lang],
          `STATE_${stateTime.key}`))
      }
    });
    machine.workedArea.forEach(worked => {
      newData[`WORKED_AREA_${worked.activityName}`] = conversion.translateDecimalToValueText(worked.value / 10000);
      if (!additionalHeaders.find(header => header.value === `WORKED_AREA_${worked.activityName}`)) {
        additionalHeaders.push(getAdditionalHeader(
          i18n.t('machineSummaryReport.HEADERS.workedArea', { lang }) + worked.activityName,
          `WORKED_AREA_${worked.activityName}`))
      }
    });

    if (machine.statesArea){
      machine.statesArea.forEach(stateArea => {
        newData[`AREA_${stateArea.key}`] = conversion.translateDecimalToValueText(stateArea.value / 10000);
        if (!additionalHeaders.find(header => header.value === `AREA_${stateArea.key}`)) {
          additionalHeaders.push(getAdditionalHeader(
            i18n.t('machineSummaryReport.HEADERS.areaByState', { lang }) + stateArea.name[lang],
            `AREA_${stateArea.key}`))
        }
      });
    }
    if(machine.statesDistance){
      machine.statesDistance.forEach(stateDistance => {
        newData[`DISTANCE_${stateDistance.key}`] =conversion.translateDecimalToValueText(stateDistance.value)
        if (!additionalHeaders.find(header => header.value === `DISTANCE_${stateDistance.key}`)) {
          additionalHeaders.push(getAdditionalHeader(
            i18n.t('machineSummaryReport.HEADERS.distanceByState', { lang }) + stateDistance.name[lang],
            `DISTANCE_${stateDistance.key}`))
        }
      });
    }
    reportData.push(newData);
  })
  return { data: reportData, additionalHeaders };
}

const getReportDataToExport = async (thingsData, activities, sector) => {
  let result = []

  let activitiesMap = {}
  if(activities.length>0){
    activities.forEach(item => {
      activitiesMap[item.key] = item
    })
  }

  thingsData.forEach(thing => {
    let valuesMap = getValuesMapToExport(thing, activitiesMap, sector)
    let row = {}    
    let headers= null;
    switch(sector){
      case reportToExportConstants.sectors.FIELD_ENGINEERING:
        headers= reportToExportConstants.engineeringHeaders;
      break;
      case reportToExportConstants.sectors.FARM:
        headers = reportToExportConstants.farmHeaders;
      break;
      case reportToExportConstants.sectors.TRANSPORT:
        headers = reportToExportConstants.transportHeaders;
      break;
      case reportToExportConstants.sectors.HARVEST:
        headers= reportToExportConstants.harvestHeaders;
      break;
      default:
        headers = reportToExportConstants.engineeringHeaders;
      break;
    }

    
    headers.forEach(header => {
      row[header.text] = valuesMap[header.value]
    })
    result.push(row);
  })

  return result;

}


const getValuesMapToExport = (thing, activitiesMap, sector) => {
  let workedAreaValuesMap = {}
  thing.workedArea.forEach(item => {
    workedAreaValuesMap[item.activityId] = item.value
  })
  let valuesMap = {}
  //Columnas comunes a todos los sectores
  valuesMap['thingName'] = thing.thingName
  valuesMap['activityName'] = thing.currentActivityName
  valuesMap['availableTime'] = thing.availableTime ? thing.availableTime.text : ''
  valuesMap['productiveTime'] = thing.productiveTime ? thing.productiveTime.text : ''
  valuesMap['improductiveTime'] = thing.improductiveTime ? thing.improductiveTime.text : ''
  valuesMap['totalStatesTime'] = thing.totalStatesTime ? thing.totalStatesTime.text : ''
  valuesMap['statesAverageSpeed'] = thing.statesAverageSpeed ? thing.statesAverageSpeed.text : ''
  valuesMap['maxSpeed'] = thing.maxSpeed ? thing.maxSpeed.text : ''
  valuesMap['workingStateAverageSpeed'] = thing.workingStateAverageSpeed ? thing.workingStateAverageSpeed.text : ''
  valuesMap['distance'] = thing.distance ? thing.distance.text : ''

  let states = []
  let activities = []

  switch(sector){
    case reportToExportConstants.sectors.FIELD_ENGINEERING:
      states = reportToExportConstants.engineeringStates;
      activities = reportToExportConstants.engineeringActivities;
    break;
    case reportToExportConstants.sectors.FARM:
      states = reportToExportConstants.farmStates;
      activities = reportToExportConstants.farmActivities;
  
      //Columnas exclusivas de finca
      valuesMap['workFrontName'] = thing.workFrontName ? thing.workFrontName : ''
      valuesMap['plantationAvailableTime'] = thing.plantationAvailableTime ? thing.plantationAvailableTime.text : ''
      valuesMap['averageRPM'] = thing.averageRPM ? thing.averageRPM.text : ''
    break;
    case reportToExportConstants.sectors.TRANSPORT:
        states = reportToExportConstants.transportStates;
        
        valuesMap['workFrontName'] = thing.workFrontName ? thing.workFrontName : ''
        valuesMap['plantationAvailableTime'] = thing.plantationAvailableTime ? thing.plantationAvailableTime.text : ''
        valuesMap['averageRPM'] = thing.averageRPM ? thing.averageRPM.text : ''
        
        //POR AHORA VACÍOS Y DESPUES HAY QUE VER COMO VIENEN DEL BACK CUANDO SE TRAIGA ESTE DATO.
        valuesMap['anomalies'] = ''
        valuesMap['tonsPerDay'] = ''
        valuesMap['tonsPerHour'] = ''
        valuesMap['TCHP'] = ''
    break;
    case reportToExportConstants.sectors.HARVEST:
      states = reportToExportConstants.harvestStates;
      activities = reportToExportConstants.harvestActivities;
    
      valuesMap['workFrontName'] = thing.workFrontName ? thing.workFrontName : ''
      valuesMap['averageRPM'] = thing.averageRPM ? thing.averageRPM.text : ''
      valuesMap['driveUnitName'] = thing.driveUnitName ? thing.driveUnitName : ''
    break;
  }

  //columnas de estado y área trabajada segun el sector. 
  states.forEach(key => {
    valuesMap[key] = thing['STATE_' + key] ? thing['STATE_' + key].text : ''
    valuesMap['DISTANCE_' + key] = thing['DISTANCE_' + key] ? thing['DISTANCE_' + key].text : ''
    valuesMap['AREA_' + key] = thing['AREA_' + key] ? thing['AREA_' + key].text :'' 
  })

  if(activities.length>0){
    activities.forEach(key => {
      valuesMap[key] = workedAreaValuesMap[activitiesMap[key].id] ? conversion.translateDecimalToValueText(workedAreaValuesMap[activitiesMap[key].id] / 10000).text : ''
    })
  }

  return valuesMap;
}



/**
 * Procesa los datos para el detalle del reporte
 * @param data
 * @param lang
 * @param timeOffset
 * @returns
 */
const processDetailData = (data, lang, timeOffset) => {
  const mapStateReferences = [];
  const reportData = [];
  const headerOptions = {
    boolean: [
      i18n.t('utils.YES', { lang }),
      i18n.t('utils.NO', { lang })
    ]
  };
  const headers = MachineSummaryReportConstants.detailHeaders(headerOptions, lang);
  let newData;
  data.forEach((d, index) => {
    const trackTimestampConversion = ReportConversionUtil.applyTimezone(d.trackTimestamp, timeOffset);
    const timestampConversion = ReportConversionUtil.applyTimezone(d.timestamp, timeOffset);

    const posTimestampColor = reportsUtil.getTimestampColor(d.trackTimestamp);
    const comTimestampColor = reportsUtil.getTimestampColor(d.timestamp);
    newData = {
      id: `${index}-${d.thingId}`,
      thingId: d.thingId,
      thingName: d.thingName,
      workFrontId: d.workFrontId,
      workFrontName: d.workFrontName,
      currentActivityId: d.currentActivityId,
      currentActivityName: d.currentActivityName,
      posTimestampColor,
      comTimestampColor,
      trackTimestamp: new ValueText(d.trackTimestamp, trackTimestampConversion.dateString + ' ' + trackTimestampConversion.timeString),
      timestamp: new ValueText(d.timestamp, timestampConversion.dateString + ' ' + timestampConversion.timeString),
      stateId: d.stateId,
      stateName: d.stateName ? d.stateName[lang] : undefined,
      position: [Number(d.lat), Number(d.lng)],
      speed: d.speed != null && d.direction != null ? new ValueText(Number(d.speed.toFixed(2)),
        ReportConversionUtil.speedToString(Number(d.speed.toFixed(2)), d.direction)) : '',
      harvestSpeed: d.harvestSpeed != null ? new ValueText(Number(Number(d.harvestSpeed).toFixed(2)),
        ReportConversionUtil.speedToString(Number(Number(d.harvestSpeed).toFixed(2)), -1)) : undefined,
      rpm: new ValueText(parseInt(d.rpm, 10) || 0, d.rpm || undefined),
      // PM Vigia
      engineRPM: d.engineRPM,
      oilSensor: d.oilSensor != null
        ? new ValueText(d.oilSensor, reportsUtil.getBooleanText(d.oilSensor, lang))
        : undefined,
      actuator: d.actuator != null
        ? new ValueText(d.actuator, reportsUtil.getBooleanText(d.actuator, lang))
        : undefined,
      batteryTension: d.batteryTension,
      temperature: d.temperature != null && d.temperature !== '999'
        ? new ValueText(d.temperature, conversionUtil.temperatureToStringTranslated(lang, d.temperature))
        : undefined,
      // -----------------------------------------------------------------------------------
      driveUnitId: d.driveUnitId,
      driveUnitName: d.driveUnitName,
      farm: d.farm,
      ecologicalUnit: d.ecologicalUnit,
      variety: d.variety,
      age: d.age,
      area: d.area,
      tch: d.tch,
      tchp: d.tchp,
      grooves: d.grooves,
      kgPerGroove: d.kgPerGroove,
      groovesPerHectare: d.groovesPerHectare,
      machineEfficiency: d.machineEfficiency,
      headWidth: d.headWidth,
      distanceToMill: d.distanceToMill,
      plantingWidth: d.plantingWidth,
      nivelationWidth: d.nivelationWidth,
      driverId: d.driverId
    };

    const state = newData.stateName;
    const stateInMapReferences = state != null ? mapStateReferences.find(ref => ref.name === state) : null;
    if (state && !stateInMapReferences) {
      let color = '#f44336';

      if (d.thingStateColor) {
        color = d.thingStateColor;
      }

      mapStateReferences.push({
        name: state,
        color,
        isInterval: false,
        title: state
      });
    }
    reportData.push(newData);
  });
  const speedMap = MachineSummaryReportConstants.detailSpeedMap(lang);
  const stateMap = MachineSummaryReportConstants.detailStateMap(lang, mapStateReferences);
  return { data: reportData, headers, maps: [stateMap, speedMap] };
}

/**
 * Obtener un header adicional
 * @param text
 * @param value
 * @returns
 */
const getAdditionalHeader = (text, value) => ({
  text,
  align: 'left',
  filterType: FilterType.textField,
  selected: true,
  sortable: true,
  type: DataType.number,
  value,
  width: 200
})

/**
 * Para crear los gráficos de torta
 * @param data
 * @param formattedData
 * @param labels
 * @param colors
 * @param name
 * @param id
 * @param from
 * @param to
 * @param offset
 */
const getPieChart = (data, formattedData, labels, colors, name, id, from, to, offset) => {
  const options = new Options();
  options.tooltips.mode = 'point';
  const chart = new Chart();
  // id
  chart.id = id;
  // tipo
  chart.type = ChartType.PIE;
  // dataset
  const dataset = new Dataset();
  dataset.formattedTooltipData.label = formattedData;
  // datos
  dataset.data = data;
  let total = 0;
  data.forEach(d => total += d);
  // labels
  chart.data.labels = [];
  labels.forEach((label, index) => {
    let percentage = 0;
    if (total) {
      percentage = Util.roundDecimals((data[index] / total) * 100);
    }
    chart.data.labels.push(label + ' (' + percentage + '%)');
  });
  // colores
  dataset.backgroundColor = colors;
  dataset.borderColor = dataset.backgroundColor;
  // Agrego el dataset creado
  chart.data.datasets.push(dataset);
  // nombre
  chart.name = name;
  const fromConversion = ReportConversionUtil.applyTimezone(from, offset);
  const toConversion = ReportConversionUtil.applyTimezone(to, offset);
  options.title.text = fromConversion.dateString + ' ' +
    fromConversion.timeString + ' - ' + toConversion.dateString + ' ' + toConversion.timeString;
  // opciones
  const yAxes = new Axes(ChartType.PIE);
  const xAxes = new Axes(ChartType.PIE);
  options.scales.yAxes.push(yAxes);
  options.scales.xAxes.push(xAxes);
  options.tooltips.callbacks = {
    label: function (tooltipItem, data) {
      let index = tooltipItem.index;
      let label = '';
      if (data.datasets && data.datasets.length && (data.datasets[0].formattedTooltipData.label.length >= index) && (data.labels.length >= index)) {
        label = data.labels[index] + ': ' + data.datasets[0].formattedTooltipData.label[index];
      }
      return label;
    }
  }
  chart.options = options;
  // desactivo las data labels
  chart.showDatalabels = false;
  return chart;
}

/**
 * Generar los gráficos para el reporte
 * @param reportData
 * @param from
 * @param to
 * @param lang
 * @param offset
 * @param timeFormat
 */
const getcharts = async (reportData, from, to, lang, offset, timeFormat) => {
  // ids de cosas, conductores (driverCodes) y frentes de trabajo
  const things = [...new Set(reportData.things.map(t => ({ id: t.thingId, name: t.thingName, typeKey: t.typeKey })))];
  const drivers = [...new Set(reportData.drivers.map(d => ({ id: d.driverCode, name: d.driverName })))];
  const workFronts = [...new Set(reportData.workFronts.map(wf => ({ id: wf.workFrontId, name: wf.workFrontName })))];

  return {
    timeByStateCharts: await getAllTimeByStateCharts(reportData, things, drivers, workFronts, from, to, lang, offset, timeFormat),
    availableTimeCharts: await getAllAvailableTimeCharts(reportData, things, drivers, workFronts, from, to, lang, offset, timeFormat)
  };
}

/**
 * Gráfico de tiempo por estado
 * @param reportData
 * @param things
 * @param drivers
 * @param workFronts
 * @param from
 * @param to
 * @param lang
 * @param offset
 * @param timeFormat
 * @returns
 */
const getAllTimeByStateCharts = async (reportData, things, drivers, workFronts, from, to, lang, offset, timeFormat) => {
  // resultado: gráficos por cosas, conductores y frentes de trabajo
  const result = {
    thingCharts: [],
    things: [
      {
        id: 'ALL',
        name: i18n.t('machineSummaryReport.CHARTS.all', { lang })
      },
      ...things
    ],
    driverCharts: [],
    drivers: [
      {
        id: 'ALL',
        name: i18n.t('machineSummaryReport.CHARTS.all', { lang })
      },
      ...drivers
    ],
    workFrontCharts: [],
    workFronts: [
      {
        id: 'ALL',
        name: i18n.t('machineSummaryReport.CHARTS.all', { lang })
      },
      ...workFronts
    ]
  };

  // COSAS -> GRÁFICO DE TODAS
  let chart = getTimeByStatesChart(reportData.things, i18n.t('machineSummaryReport.CHARTS.equipments', { lang }),
    'timeByStates-things', from, to, offset, lang, timeFormat);
  result.thingCharts.push({
    id: 'ALL',
    chart
  });
  // COSAS -> gráfico por cada una
  let reportDataThings;
  let thing;
  for (let i = 1; i < result.things.length; i++) {
    thing = result.things[i];
    reportDataThings = reportData.things.filter(t => t.thingId === thing.id);
    chart = getTimeByStatesChart(reportDataThings, i18n.t('machineSummaryReport.CHARTS.equipments',
      { lang }), 'timeByStates-things', from, to, offset, lang, timeFormat);
    result.thingCharts.push({
      id: thing.id,
      chart
    });
  }

  // CONDUCTORES -> GRÁFICO DE TODOS
  chart = getTimeByStatesChart(reportData.drivers, i18n.t('machineSummaryReport.CHARTS.rrhh', { lang }),
    'timeByStates-drivers', from, to, offset, lang, timeFormat);
  result.driverCharts.push({
    id: 'ALL',
    chart
  });
  // CONDUCTORES -> GRÁFICO POR CADA UNO
  let reportDataDrivers;
  let driver;
  for (let i = 1; i < result.drivers.length; i++) {
    driver = result.drivers[i];
    reportDataDrivers = reportData.drivers.filter(d => d.driverCode === driver.id);
    chart = getTimeByStatesChart(reportDataDrivers, i18n.t('machineSummaryReport.CHARTS.rrhh', { lang }),
      'timeByStates-drivers', from, to, offset, lang, timeFormat);
    result.driverCharts.push({
      id: driver.id,
      chart
    });
  }

  // FRENTES DE TRABAJO -> GRÁFICO DE TODOS
  chart = getTimeByStatesChart(reportData.workFronts, i18n.t('machineSummaryReport.CHARTS.workFronts', { lang }),
    'timeByStates-workFronts', from, to, offset, lang, timeFormat);
  result.workFrontCharts.push({
    id: 'ALL',
    chart
  });
  // FRENTES DE TRABAJO -> gráfico por cada uno
  let reportDataWorkFronts;
  let workFront;
  for (let i = 1; i < result.workFronts.length; i++) {
    workFront = result.workFronts[i];
    reportDataWorkFronts = reportData.workFronts.filter(wf => wf.workFrontId === workFront.id);
    chart = getTimeByStatesChart(reportDataWorkFronts, i18n.t('machineSummaryReport.CHARTS.workFronts', { lang }),
      'timeByStates-workFronts', from, to, offset, lang, timeFormat);
    result.workFrontCharts.push({
      id: workFront.id,
      chart
    });
  }

  return result;
}

/**
 * Método para obtener un gráfico para un arreglo de datos, puede usarse para generar cualquier gráfico de tiempo por estado,
 * ya sea por cosa, por conductor o por frente de trabajo
 * @param data
 * @param chartName
 * @param chartId
 * @param from
 * @param to
 * @param offset
 * @param lang
 * @param timeFormat
 * @returns
 */
const getTimeByStatesChart = (data, chartName, chartId, from, to, offset, lang, timeFormat) => {
  // datos de los estados
  const states = [];
  const stateFormattedData = [];
  const stateLabels = [];
  const stateColors = [];
  let state;

  // iteración para sumar los datos y obtener los labels y colores de los estados
  data.forEach(element => {
    element.statesTime.forEach(stateTimeElement => {
      // busco el estado en el arreglo de datos
      state = states.find(s => s._id === stateTimeElement.key);
      if (state == null) {
        // dato numérico para el gráfico
        states.push({
          _id: stateTimeElement.key,
          value: stateTimeElement.value
        });
        // color y label
        const stateColor = stateTimeElement.color;
        const stateName = stateTimeElement.name[lang];
        stateColors.push(stateColor);
        stateLabels.push(stateName);
      } else {
        state.value += stateTimeElement.value;
      }
    })
  })

  // valores formateados para los estados
  states.forEach(stateElement => stateFormattedData.push(ReportConversionUtil.secondsToStringFormatted(stateElement.value, timeFormat)));

  // obtener gráfico
  return getPieChart(states.map(s => s.value), stateFormattedData, stateLabels, stateColors, chartName, chartId, from, to, offset);
}

/**
 * Gráficos de tiempo disponible
 * @param reportData
 * @param things
 * @param drivers
 * @param workFronts
 * @param from
 * @param to
 * @param lang
 * @param offset
 * @param timeFormat
 * @returns
 */
const getAllAvailableTimeCharts = async (reportData, things, drivers, workFronts, from, to, lang, offset, timeFormat) => {
  const typeKeys = [...new Set(things.map(t => t.typeKey))];
  const includePlantationAvailableTime = typeKeys.includes('FARM_TRACTOR');
  const enterpriseId = JSON.parse(localStorage.getItem('enterpriseId'));

  // resultado: gráficos por cosas, conductores y frentes de trabajo
  const result = {
    includePlantationAvailableTime,
    thingCharts: [],
    plantationThingCharts: includePlantationAvailableTime ? [] : null,
    things: [
      {
        id: 'ALL',
        name: i18n.t('machineSummaryReport.CHARTS.all', { lang })
      },
      ...things
    ],
    plantationThings: includePlantationAvailableTime ? [] : null,
    driverCharts: [],
    plantationDriverCharts: includePlantationAvailableTime ? [] : null,
    drivers: [
      {
        id: 'ALL',
        name: i18n.t('machineSummaryReport.CHARTS.all', { lang })
      },
      ...drivers
    ],
    plantationDrivers: includePlantationAvailableTime ? [] : null,
    workFrontCharts: [],
    plantationWorkFrontCharts: includePlantationAvailableTime ? [] : null,
    workFronts: [
      {
        id: 'ALL',
        name: i18n.t('machineSummaryReport.CHARTS.all', { lang })
      },
      ...workFronts
    ],
    plantationWorkFronts: includePlantationAvailableTime ? [] : null
  };

  // config de empresa
  const statesConfig = await enterpriseApi.getEnterpriseStateConfiguration();
  // mapa de estados por tipo de equipo
  const chartStatesByEnterprise = await stateApi.getStatesByEnterpriseId(enterpriseId, typeKeys);
  // stateTypeKeys de estados configurados en BD(de todos los equipos juntos)
  let allTypes = [];
  typeKeys.forEach(type => allTypes.push(...Object.values(chartStatesByEnterprise[type])));
  const stateTypeKeys = [...new Set(allTypes)];
  // mapa de estados
  const statesMapped = await stateApi.getStatesTypeByKey(stateTypeKeys);

  // COSAS -> GRÁFICO DE TODAS
  let charts = getAvailableTimeChart(reportData.things, includePlantationAvailableTime, i18n.t('machineSummaryReport.CHARTS.equipments',
    { lang }), 'availableTime-things', from, to, offset, lang, timeFormat, statesConfig, statesMapped, stateTypeKeys, chartStatesByEnterprise);
  result.thingCharts.push({
    id: 'ALL',
    chart: charts.availableTimeChart
  });
  if (includePlantationAvailableTime) {
    result.plantationThingCharts.push({
      id: 'ALL',
      chart: charts.plantationAvailableTimeChart
    });
  }
  // COSAS -> gráfico por cada una
  let reportDataThings;
  let thing;
  for (let i = 1; i < result.things.length; i++) {
    thing = result.things[i];
    reportDataThings = reportData.things.filter(t => t.thingId === thing.id);
    charts = getAvailableTimeChart(reportDataThings, includePlantationAvailableTime, i18n.t('machineSummaryReport.CHARTS.equipments', { lang }),
      'availableTime-things', from, to, offset, lang, timeFormat, statesConfig, statesMapped, stateTypeKeys, chartStatesByEnterprise);
    result.thingCharts.push({
      id: thing.id,
      chart: charts.availableTimeChart
    });
    if (includePlantationAvailableTime) {
      result.plantationThingCharts.push({
        id: `PLANTATION-${thing.id}`,
        chart: charts.plantationAvailableTimeChart
      });
    }
  }

  // CONDUCTORES -> GRÁFICO DE TODOS
  charts = getAvailableTimeChart(reportData.drivers, includePlantationAvailableTime, i18n.t('machineSummaryReport.CHARTS.rrhh',
    { lang }), 'availableTime-drivers', from, to, offset, lang, timeFormat, statesConfig, statesMapped, stateTypeKeys);
  result.driverCharts.push({
    id: 'ALL',
    chart: charts.availableTimeChart
  });
  if (includePlantationAvailableTime) {
    result.plantationDriverCharts.push({
      id: 'ALL',
      chart: charts.plantationAvailableTimeChart
    });
  }
  // CONDUCTORES -> gráfico por cada uno
  let reportDataDrivers;
  let driver;
  for (let i = 1; i < result.drivers.length; i++) {
    driver = result.drivers[i];
    reportDataDrivers = reportData.drivers.filter(d => d.driverCode === driver.id);
    charts = getAvailableTimeChart(reportDataDrivers, includePlantationAvailableTime, i18n.t('machineSummaryReport.CHARTS.rrhh',
      { lang }), 'availableTime-drivers', from, to, offset, lang, timeFormat, statesConfig, statesMapped, stateTypeKeys);
    result.driverCharts.push({
      id: driver.id,
      chart: charts.availableTimeChart
    });
    if (includePlantationAvailableTime) {
      result.plantationDriverCharts.push({
        id: `PLANTATION-${driver.id}`,
        chart: charts.plantationAvailableTimeChart
      });
    }
  }

  // FRENTES DE TRABAJO -> GRÁFICO DE TODOS
  charts = getAvailableTimeChart(reportData.workFronts, includePlantationAvailableTime, i18n.t('machineSummaryReport.CHARTS.workFronts',
    { lang }), 'availableTime-workFronts', from, to, offset, lang, timeFormat, statesConfig, statesMapped, stateTypeKeys);
  result.workFrontCharts.push({
    id: 'ALL',
    chart: charts.availableTimeChart
  });
  if (includePlantationAvailableTime) {
    result.plantationWorkFrontCharts.push({
      id: 'ALL',
      chart: charts.plantationAvailableTimeChart
    });
  }
  // FRENTES DE TRABAJO -> gráfico por cada uno
  let reportDataWorkFronts;
  let workFront;
  for (let i = 1; i < result.workFronts.length; i++) {
    workFront = result.workFronts[i];
    reportDataWorkFronts = reportData.workFronts.filter(wf => wf.workFrontId === workFront.id);
    charts = getAvailableTimeChart(reportDataWorkFronts, includePlantationAvailableTime, i18n.t('machineSummaryReport.CHARTS.workFronts',
      { lang }), 'availableTime-workFronts', from, to, offset, lang, timeFormat, statesConfig, statesMapped, stateTypeKeys);
    result.workFrontCharts.push({
      id: workFront.id,
      chart: charts.availableTimeChart
    });
    if (includePlantationAvailableTime) {
      result.plantationWorkFrontCharts.push({
        id: `PLANTATION-${workFront.id}`,
        chart: charts.plantationAvailableTimeChart
      });
    }
  }

  // completo los arreglos para los selectores de los gráficos de plantación
  if (includePlantationAvailableTime) {
    result.plantationThings = result.things.map(t => ({
      id: t.id !== 'ALL' ? `PLANTATION-${t.id}` : t.id,
      name: t.name
    }));
    result.plantationDrivers = result.drivers.map(d => ({
      id: d.id !== 'ALL' ? `PLANTATION-${d.id}` : d.id,
      name: d.name
    }));
    result.plantationWorkFronts = result.workFronts.map(wf => ({
      id: wf.id !== 'ALL' ? `PLANTATION-${wf.id}` : wf.id,
      name: wf.name
    }));
  }

  return result;
}

/**
 * Método para obtener un gráfico para un arreglo de datos, puede usarse para generar cualquier gráfico de tiempo disponible,
 * ya sea por cosa, por conductor o por frente de trabajo
 *
 * Genera un segundo gráfico de tiempo disponible de plantación si el flag includePlantationAvailableTime está en true
 * @param data
 * @param includePlantationAvailableTime
 * @param chartName
 * @param chartId
 * @param from
 * @param to
 * @param offset
 * @param lang
 * @param timeFormat
 * @param statesConfig
 * @param statesMapped
 * @param stateTypeKeys
 * @param chartStatesByEnterprise
 * @returns
 */
const getAvailableTimeChart = (data, includePlantationAvailableTime = false, chartName, chartId,
  from, to, offset, lang, timeFormat, statesConfig, statesMapped, stateTypeKeys, chartStatesByEnterprise = null) => {
  const result = {
    availableTimeChart: null,
    plantationAvailableTimeChart: null
  };
  const stateValues = [];
  const stateLabels = [];
  const stateColors = [];
  let stateFormattedData = [];
  let chartData = getStateData(data, stateTypeKeys, statesConfig, statesMapped, lang,
    includePlantationAvailableTime, chartStatesByEnterprise);

  // separo cada dato
  for (let index = 0; index < chartData.stateData.length; index++) {
    const data = chartData.stateData[index];
    stateValues[index] = data.value;
    stateColors[index] = data.color;
    stateLabels[index] = data.label;
  }

  // Agrego en primer lugar el tiempo disponible
  stateValues.unshift(chartData.availableTime);
  stateColors.unshift('rgb(133, 241, 114)');
  stateLabels.unshift(i18n.t('machineSummaryReport.CHARTS.available', { lang }));

  stateValues.forEach(value => stateFormattedData.push(ReportConversionUtil.secondsToStringFormatted(value, timeFormat)));
  result.availableTimeChart = getPieChart(stateValues, stateFormattedData, stateLabels, stateColors,
    chartName, chartId, from, to, offset);

  // tiempo disponible de plantación
  if (includePlantationAvailableTime) {
    const plantationAvailableTimeStates = clone(stateValues);
    plantationAvailableTimeStates[0] = chartData.plantationAvailableTime;
    const plantationAvailableTimeFormattedData = [];
    plantationAvailableTimeStates.forEach(value => plantationAvailableTimeFormattedData.push(ReportConversionUtil.secondsToStringFormatted(value, timeFormat)));
    result.plantationAvailableTimeChart = getPieChart(
      plantationAvailableTimeStates, plantationAvailableTimeFormattedData, stateLabels, stateColors,
      chartName, `${chartId}-plantation`, from, to, offset);
  }

  return result;
}

const getStateData = (data, stateTypeKeys, statesConfig, statesMapped, lang, includePlantationAvailableTime, chartStatesByEnterprise = null) => {
  let stateData = [];
  let availableTime = 0;
  let plantationAvailableTime = 0;
  let state;

  // array de estados que se deben mostrar en el gráfico
  stateTypeKeys.forEach(key => {
    let state = statesMapped[key];
    if (state) {
      stateData.push({
        _id: key,
        value: 0,
        color: state.color,
        label: state.name[lang]
      });
    }
  });

  data.forEach(element => {
    availableTime += element.availableTime;
    if (includePlantationAvailableTime) {
      plantationAvailableTime += element.plantationAvailableTime;
    }

    element.statesTime.forEach(stateTimeElement => {
      if (chartStatesByEnterprise) { // Para el gráfico de equipos
        // Obtengo el arreglo de estados que puede visualizar el tipo de equipo
        const type = chartStatesByEnterprise[element.typeKey];
        if (type) {
          const stateType = type.find(s => s === stateTimeElement.key);
          // Si el tipo del equipo que se intenta agregar al gráfico está dentro del arreglo de tipos de equipos lo agrego
          if (stateType) {
            // busco el estado en el arreglo de datos
            state = stateData.find(s => s._id === stateTimeElement.key);
            if (state) {
              state.value += stateTimeElement.value;
              state.label = stateTimeElement.name
                ? stateTimeElement.name[lang]
                : (statesConfig[stateTimeElement.key].stateName ? statesConfig[stateTimeElement.key].stateName[lang] : statesMapped[stateTimeElement.key].name[lang]);
              state.color = stateTimeElement.color
                ? stateTimeElement.color
                : (statesConfig[stateTimeElement.key].stateColor ? statesConfig[stateTimeElement.key].stateColor : statesMapped[stateTimeElement.key].color);
            }
          }
        }
      } else {
        state = stateData.find(s => s._id === stateTimeElement.key);
        if (state) {
          state.value += stateTimeElement.value;
          state.label = stateTimeElement.name
            ? stateTimeElement.name[lang]
            : (statesConfig[stateTimeElement.key].stateName ? statesConfig[stateTimeElement.key].stateName[lang] : statesMapped[stateTimeElement.key].name[lang]);
          state.color = stateTimeElement.color
            ? stateTimeElement.color
            : (statesConfig[stateTimeElement.key].stateColor ? statesConfig[stateTimeElement.key].stateColor : statesMapped[stateTimeElement.key].color);
        }
      }
    })
  });

  return { stateData, availableTime, plantationAvailableTime };
}

export default {
  getReportByThingsDeferred,
  getReportByDriveUnitsDeferred,
  getReportByThings,
  getReportByDriveUnits,
  getAutoReportByThings,
  getAutoReportByDriveUnits,
  processSummaryDataDetail,
  processSummaryData,
  getReportDataToExport,

};
